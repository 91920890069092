import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchParcelDetails } from './parcelDetailsAPI';
import { ParcelDetailsRequest, ParcelDetailsResponse } from '../../entities/ApiModel';

export interface ParcelDetailsState {
    parcelDetails: ParcelDetailsResponse;
    status: 'idle' | 'loading' | 'failed';
}
export const resetParcelDetail = createAction('REVERT_PARCEL_STATE');

const initialState: ParcelDetailsState = {
    parcelDetails: {
        propertyDetails: {},
        overviewDetails: {},
        salesDetails: {},
        physicalDetails: {},
        assessmentDetails: {},
        directStarter: {},
        referenceStarter: {},
        mapProperties: {}
    },
    status: 'idle',
};

const roundOffProximity = (starter: any) => {
    let referenceStarter = starter.reference_starter_details;
    // referenceStarter.forEach(function (starter: any) {
    //     if (starter?.search_proximity && !isNaN(starter?.search_proximity)) {
    //         starter.search_proximity = parseFloat(starter.search_proximity).toFixed(2);
    //         starter.search_proximity_number = parseFloat(starter.search_proximity ?? 0);
    //     }
    //     starter.policy_effective_year = parseInt(starter.policy_effective_date.slice(-4) ?? 9999);
    // });

    // if (starter.search_status === "radial search") {
    //     referenceStarter.sort(function (a: any, b: any) {
    //         let af = a.search_proximity_number;
    //         let bf = b.search_proximity_number;
    //         let as = a.policy_effective_year;
    //         let bs = b.policy_effective_year;
    //         // If first value is same
    //         if (af == bf) {
    //             return (as > bs) ? -1 : (as < bs) ? 1 : 0;
    //         } else {
    //             return (af < bf) ? -1 : 1;
    //         }
    //     });
    // }
    return referenceStarter;
}

export const getParcelDetails = createAsyncThunk(
    'get/parcelDetails',
    async (propertyRequest: ParcelDetailsRequest) => {
        const response = await fetchParcelDetails(propertyRequest.propertyId, propertyRequest.fips);
        return response;
    }
);

export const parcelDetailsSlice = createSlice({
    name: 'parcelDetails',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getParcelDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getParcelDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.parcelDetails.propertyDetails = action.payload.propertyDetails;
                state.parcelDetails.overviewDetails = action.payload.overviewDetails;
                state.parcelDetails.salesDetails = action.payload.salesDetails;
                state.parcelDetails.physicalDetails = action.payload.physicalDetails;
                state.parcelDetails.assessmentDetails = action.payload.assessmentDetails;
                state.parcelDetails.directStarter = action.payload.directStarter;
                state.parcelDetails.referenceStarter = action.payload.referenceStarter;
                state.parcelDetails.mapProperties = action.payload.mapProperties;

                if (action.payload.referenceStarter
                    && action.payload.referenceStarter.reference_starter_details) {
                    roundOffProximity(action.payload.referenceStarter);
                }
            })
            .addCase(getParcelDetails.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetParcelDetail, () => initialState)
            ;
    },
});

export const parcelDetailsData = (state: RootState) => state.rootReducer.parcelDetails;
export default parcelDetailsSlice.reducer;
