import React from "react";
import {
    PropertyLabel, PropertyValue
} from "../../../components/StyledComponents/ParcelDetailStyles"
import { Box, Grid, styled, Paper, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './propertyOverview.module.css';
import BingMap from "../../../components/ui/map/BingMap";
import NoMap from "../../../components/ui/map/NoMap";
import { formatMailingAddress, formatUnitNumberField, formatZip4Field } from "../../../utils/common/commonUtils";

//Grid Item
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface Props {
    returnToSearch: any,
    overview: any,
    lotNo: any,
    tractNo: any,
    mapProperties: any
}

function PropertyOverview({ returnToSearch, overview, lotNo, tractNo, mapProperties }: Props) {
    let overviewData: any;
    let latLong: any;
    if (overview) {
        overviewData = overview;
    }

    if (mapProperties && mapProperties.match_code === "1" && mapProperties.lat_long_details && mapProperties.lat_long_details[0]) {
        latLong = mapProperties.lat_long_details[0];
    }

    return (<div>
        {

            overview
            && overviewData
            &&
            <Grid container>
                <Grid item xs={5} sx={{ minHeight: '475px' }}>
                    <Box className={styles.returnBox}>
                        <Item sx={{ paddingLeft: "0px", boxShadow: 0 }}>
                            <Button onClick={returnToSearch} className={styles.customButtonStyle} data-testid="ReturnToSearch"
                                variant="outlined" startIcon={<ArrowBackIcon />}>
                                Return to Search
                            </Button>
                        </Item>
                    </Box>
                    <Box marginTop={'7%'} sx={{ padding: '25px' }}>
                        <Grid container>
                            <Grid item xs={4}>
                                <PropertyLabel>Property Address</PropertyLabel>
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    overviewData.property_full_address &&
                                    <PropertyValue data-testid="FullAddress">{overviewData.property_full_address}</PropertyValue>
                                }
                                <PropertyValue data-testid="UnitNumber">{formatUnitNumberField(overviewData.property_unit_type.trim(), overviewData.property_unit_number.trim())}</PropertyValue>
                                <PropertyValue data-testid="CityState">{formatMailingAddress("", overviewData.city, overviewData.state, formatZip4Field(overviewData.zip, overviewData.zip4), "")} </PropertyValue>
                                {/* {overviewData.city}{overviewData.city && (overviewData.state || overviewData.zip) ? ", " : ""}{overviewData.state} {overviewData.zip} */}
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: '15px' }}>
                            <Grid item xs={4}>
                                <PropertyLabel>APN</PropertyLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <PropertyValue data-testid="APN">{overviewData.apn}</PropertyValue>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: '15px' }}>
                            <Grid item xs={4}>
                                <PropertyLabel>Legal Description Details</PropertyLabel>
                            </Grid>
                            <Grid item xs={8}>
                                {/* <PropertyValue>Lot Number: {props.lotNo}</PropertyValue> */}
                                {/* <PropertyValue>Tract No: {props.tractNo}</PropertyValue> */}
                                <PropertyValue data-testid="LegalDescriptionDetails">{overviewData.brief_description}</PropertyValue>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: '15px' }}>
                            <Grid item xs={4}>
                                <PropertyLabel>Owner(s)</PropertyLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <PropertyValue data-testid="Owners">{overviewData.owner_name}</PropertyValue>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{ width: '100%', height: '475px' }}>
                        {
                            latLong
                            && latLong.latitude
                            && latLong.longitude
                            && (typeof Microsoft !== 'undefined')
                            &&
                            < BingMap lat={latLong.latitude} long={latLong.longitude} />
                        }
                        {
                            ((!latLong
                                || !latLong.latitude
                                || !latLong.longitude)
                                || (typeof Microsoft == 'undefined'))
                            &&
                            <NoMap />
                        }
                    </Box>
                </Grid>
            </Grid>
        }
    </div>)
}

export default PropertyOverview;