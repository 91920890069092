import type { RouteObject } from 'react-router';
import ParcelDetails from './features/parcelDetails/ParcelDetails';
import PageNotFound from './features/notFoundpage/NotFound';
import SearchLayout from './layout/searchLayout/searchLayout';
import Login from './features/authentication/Login';
import AccessDenied from './features/accessDenied/AccessDenied';
import NoContent from './features/noContent/NoContent';

const routesConfig: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <SearchLayout />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/address-search',
        element: <SearchLayout />
      },
      {
        path: '/parcel-detail/:propertyId',
        element: <ParcelDetails />
      },
      {
        path: '/access-denied',
        element: <AccessDenied />
      },
      {
        path: '/no-content',
        element: <NoContent />
      },

    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  }
];

export default routesConfig;
