import { Box, Typography } from '@mui/material'
import React from 'react'
import { SectionHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
    claimNo: string
    handleDirectStarterSectionClick: Function,
    rowNumber: string
    policyNumber: string
}

function DirectStarterHeader({ claimNo, handleDirectStarterSectionClick, rowNumber, policyNumber }: Props) {
    const [directStarterOpen, setDirectStarter] = React.useState(false);
    const handleOpenClick = () => {
        setDirectStarter(!directStarterOpen)
        handleDirectStarterSectionClick(rowNumber);
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <SectionHeader sx={{ paddingBottom: "10px" }} onClick={handleOpenClick} variant="h6">
                Direct Starter &nbsp; {directStarterOpen ? <KeyboardArrowUpIcon data-testid={policyNumber + "_DirectStarterClose"} />
                    : <KeyboardArrowDownIcon data-testid={policyNumber + "_DirectStarterOpen"} />}
            </SectionHeader>
            {
                claimNo &&
                <Typography data-testid={policyNumber + "ClaimNo"} sx={{ color: '#E00000', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {claimNo}</Typography>
            }
        </Box>
    )
}

export default DirectStarterHeader