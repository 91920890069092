import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchByPolicyNumber } from './policySearchAPI';
import { PolicySearchForm } from '../../entities/Types';
import { PolicySearchRequest } from '../../entities/ApiModel/PolicySearchRequest';

export interface PolicySearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    policySearch: Record<number, any[]>;
}

export interface PolicySearchState {
    property: PolicySearchResponse;
    formFields: PolicySearchForm;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetPolicySearchState = createAction('REVERT_POLICY_SEARCH_STATE');

const initialState: PolicySearchState = {
    property: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        policySearch: {},
    },
    formFields: { policyNumber: "", pageNo: 0 },
    status: 'idle',
};

export const searchByPolicyNumber = createAsyncThunk(
    'PolicySearch/getPropertyByPolicy',
    async (PolicySearchRequest: PolicySearchRequest) => {
        const response = await fetchByPolicyNumber(PolicySearchRequest);
        return response;
    }
);

export const setPolicyNumberToState = createAsyncThunk(
    'PolicySearch/setPolicyNumberToState',
    async (formData: PolicySearchForm) => {
        return formData;
    }
);

export const getPolicySearchSlice = createSlice({
    name: 'PolicySearch',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByPolicyNumber.pending, (state) => {
                state.status = 'loading';
                state.property.isExecuting = true;

                // state.property.policySearch = []
                // state.property.match_code = "";
                // state.property.message = "";
            })
            .addCase(searchByPolicyNumber.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    state.property.match_code = action.payload.match_code;
                    state.property.message = action.payload.message;
                    state.property.total_count = action.payload.total_count;
                    state.property.policySearch[pageNo] = action.payload.policy_search; // Storing results keyed by pageNo
                    state.property.isExecuting = false;
                    // state.property.policySearch = [...state.property.policySearch, ...action.payload.policy_search];
                }
            })
            .addCase(searchByPolicyNumber.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setPolicyNumberToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = {
                    policyNumber: action.payload.policyNumber,
                    pageNo: action.payload.pageNo
                }
            })
            .addCase(resetPolicySearchState, () => initialState);
    },
});

export const PolicySearchData = (state: RootState) => state.rootReducer.PolicySearchList;
export default getPolicySearchSlice.reducer;
