import { useRoutes } from 'react-router-dom';
import routesConfig from '../routesConfig';

function RootComponent() {
    const routes = useRoutes(routesConfig);
    return (
        <>
            {routes}
        </>
    )
}

export default RootComponent