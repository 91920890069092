enum MatchCodes {
    INVALID_ADDRESS = -3,
    EXCEPTION = -2,
    NO_MATCH = -1,
    UNIQUE_MATCH = 1,
    MULTIPLE_MATCH = 2,
    NO_COVERAGE = 3,
    INVALID_APN = 5,
}

export default MatchCodes;