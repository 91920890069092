import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RootState } from '../../app/store';
import { fetchByLegalSearch } from './LegalSearchAPI';
// import { LegalSearchForm } from '../../entities/Types';
import { LegalSearchRequest } from '../../../entities/ApiModel/LegalSearchRequest';
import { LegalSearchFormType } from '../../../entities/Types/LegalSearchForm';
import { RootState } from '../../../app/store';

export interface LegalSearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    LegalSearch: Record<number, any[]>;
}

export interface LegalSearchState {
    property: LegalSearchResponse;
    formFields: any;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetLegalSearchState = createAction('REVERT_POLICY_LEGAL_SEARCH_STATE');

const initialState: LegalSearchState = {
    property: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        LegalSearch: {},
    },
    formFields: {},
    status: 'idle',
};

export const searchByLegalSearch = createAsyncThunk(
    'LegalSearch/getPropertyByLegalSearch',
    async (request: LegalSearchRequest) => {
        const response = await fetchByLegalSearch(request);
        return response;
    }
);

export const setLegalSearchToState = createAsyncThunk(
    'LegalSearch/setLegalSearchToState',
    async (formData: LegalSearchFormType) => {
        return formData;
    }
);

export const getLegalSearchSlice = createSlice({
    name: 'LegalSearch',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByLegalSearch.pending, (state) => {
                state.status = 'loading';
                state.property.isExecuting = true;

                // state.property.LegalSearch = []
                // state.property.match_code = "";
                // state.property.message = "";
            })
            .addCase(searchByLegalSearch.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    // state.property.LegalSearch = action.payload.policy_search;
                    state.property.match_code = action.payload.match_code;
                    state.property.message = action.payload.message;

                    state.property.total_count = action.payload.total_count;
                    state.property.LegalSearch[pageNo] = action.payload.policy_search;
                    state.property.isExecuting = false;
                }
            })
            .addCase(searchByLegalSearch.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setLegalSearchToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = action.payload
            })
            .addCase(resetLegalSearchState, () => initialState);
    },
});

export const LegalSearchData = (state: RootState) => state.rootReducer.LegalSearchList;
export default getLegalSearchSlice.reducer;
