import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { Box, List, ListItem } from '@mui/material';
import StewartTableFooterPaginator from './StewartTableFooterPaginator';
import { formatUnitNumberField, getSessionStorage, setSessionStorage } from '../../../utils/common/commonUtils';
import { keyEntriesPerPage, keyPageNo } from '../../../utils/constants/constants';

// interface Column {
//     id: 'APN' | 'OwnerName' | 'Address' | 'City' | 'State' | 'Zip' | 'LandUse' | 'BriefLegal';
//     label: string;
//     minWidth?: number;
//     align?: 'right';
//     format?: (value: number) => string;
// }

export default function SpectrTable(props: any) {
    const { columns, rows, tabName } = props

    const defaultRowsPerPage = 10;
    const defaultPageNo = 1;

    const [page, setPage] = React.useState<number>(parseInt(getSessionStorage(keyPageNo + tabName) ?? defaultPageNo.toString()));

    const [rowsPerPage, setRowsPerPage] = React.useState<number>(parseInt(getSessionStorage(keyEntriesPerPage + tabName) ?? defaultRowsPerPage.toString()));

    const handleChangePage = (newPage: number) => {
        setSessionStorage(keyPageNo + tabName, newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (count: any) => {
        setRowsPerPage(count);
        sessionStorage.removeItem(keyPageNo + tabName);
        setSessionStorage(keyEntriesPerPage + tabName, count);
        setPage(1);
    };

    type AddressProps = {
        address: string;
        unitNumber: string;
        unitType: string;
    };

    function AddressWithUnit({ address, unitNumber, unitType }: AddressProps) {
        return (
            <List sx={{ padding: 0 }}>
                <ListItem sx={{ padding: 0 }}>{address} {formatUnitNumberField(unitType, unitNumber)}</ListItem>
            </List>
        );
    }

    const StyledTable = styled(Table)(({ theme }) => `
    tr:first-child td:last-child {
        border-top-right-radius: 4px;
    }
    tr:first-child td:first-child {
        border-top-left-radius: 4px;
    }
    tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
    }
`);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        borderTop: '1px solid #BDBDBD',
        '&:first-child': {
            borderLeft: '1px solid #BDBDBD',
        },
        '&:last-child': {
            borderRight: '1px solid #BDBDBD',
        },
        '&': {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'left',
            padding: '8px 8px 8px 12px',
        },
    }));

    const StyledTableBody = styled(TableBody)(({ theme }) => ({
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    }));

    const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
        '&': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.17000000178813934px',
            textAlign: 'left',
            color: "#212121",
            borderBottom: 'none',
        },
    }));

    return (
        <>
            <TableContainer sx={{ maxHeight: 440 }}>
                <StyledTable stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >
                            {columns.map((column: any) => (
                                <StyledTableHeaderCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <StyledTableBody>
                        {rows
                            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.apn} sx={{ border: '1px solid black' }}>
                                        {columns.map((column: any) => {
                                            let value = row[column.id];
                                            if (column.id === "address") {
                                                value = <AddressWithUnit address={row.address} unitType={row.unitType} unitNumber={row.unit} />;
                                            }
                                            return (
                                                <StyledTableCell data-testid={row.apn + "_SearchResultRow"} onClick={() => props.onRowClicked(row)} key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </StyledTableBody>
                </StyledTable>
            </TableContainer>
            <Box sx={{ paddingTop: '24px' }}>
                <Box>
                    <StewartTableFooterPaginator
                        totalRows={rows.length}//{totalRowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage} />
                </Box>
            </Box>
        </>
    );
}
