import { useEffect, useState } from 'react'
import { parseJwt } from '../../utils/common/commonUtils';
import { useMsal } from '@azure/msal-react';
import RootComponent from '../../components/RootComponent';

function Authenticated() {
    const [isValidUser, setValidUser] = useState(false);
    const { instance } = useMsal();

    useEffect(() => {
        try {
            instance.handleRedirectPromise().then((response) => {
                if (response?.account) {
                    const idToken = response.idToken;
                    let idTokenData = parseJwt(idToken);
                    if (idTokenData && idTokenData.groups && idTokenData.groups.length > 0) {
                        instance.setActiveAccount(response.account);
                        setValidUser(true);
                    } else {
                        setValidUser(false);
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = "/InvalidAccess"
                    }
                }
                else {
                    setValidUser(true);
                }
            }).catch(error => {
                setValidUser(false);
                console.error(error);
            });
        }
        catch (e) {
            setValidUser(true);
            console.log(e);
        }
    }, [instance])

    return (
        <>
            {
                isValidUser &&
                <RootComponent />
            }
        </>
    )
}

export default Authenticated