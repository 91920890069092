import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { DownloadImageButton, PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FormatDateField, getLoggingEventName } from '../../../utils/common/commonUtils';
import * as UIModel from "../../../entities/UIModel";
import { Direct_Starter_Type_Log, LoggingEvent, Reference_Starter_Type_Log, Starter__Document_Type_Log } from '../../../utils/constants/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDocument, policyDoc, resetDocumentState } from '../../../common/Slice/policyDocumentSlice';
import LoadingProgressModel from '../../../components/ui/circularProgress/LoadingProgress';
import logAPI from '../../../common/Api/logAPI';


interface Props {
    policyInfo: any,
    handlePropertyDetailsClick: Function,
    isStarter?: Boolean,
    isDirectStarter?: Boolean,
    isReferenceStarter?: Boolean,
    tabName: string,
    isPropertyDetailsOpen: boolean,
}

const HeaderResult = ({ policyInfo, handlePropertyDetailsClick, tabName, isStarter, isDirectStarter, isReferenceStarter,
    isPropertyDetailsOpen }: Props) => {
    // const [propertyDetailsOpen, setPropertyDetails] = React.useState(false);
    const [documentString, setDocumentString] = React.useState("");
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [randomUUId, setRandomUUId] = React.useState("");
    const [showWarningNotification, setWarningNotification] = React.useState(false);
    const [showErrorNotification, setErrorNotification] = React.useState(false);

    const dispatch = useAppDispatch();
    const policyDocumentList = useAppSelector((state: any) => state.rootReducer.policyDocument);//.documentList[policyInfo?.doc_image_name + policyInfo?.policy_no + policyInfo?.row_number]);
    const policyDocState = useAppSelector(policyDoc);
    let policyNumber = policyInfo?.policy_no;

    let pdfDocument: UIModel.PdfDocument = {
        base64String: documentString,
        fileName: "Policy Document",
        dDocName: ""
    }

    useEffect(() => {
        dispatch(resetDocumentState());
        // setRandomUUId(GetUUId());
    }, []);

    useEffect(() => {
        if (policyDocumentList) {
            const doc: any = policyDocumentList.documentList;
            if (doc?.isRequestCompleted && (!doc?.documentData)) {
                setIsLoading(false);
                switch (doc?.documentData?.match_code) {
                    case "-1": {
                        setWarningNotification(() => true);
                        break;
                    }
                    default: {
                        setErrorNotification(() => true);
                        break;
                    }
                }
                return;
            }
            if (doc?.documentData) {
                setIsLoading(false);
                // setCount(count + 1);
                // setDocumentString(doc?.documentData?.data)
                // openDocument();
            }
        }
    }, [policyDocumentList]);

    // const openDocument = () => {
    //     setOpenPdfViewer(true);
    //     setDocumentLoaded(true);
    // }

    // useEffect(() => {
    //     if (policyDocState?.apiStatus === "fulfilled") {
    //         setIsLoading(false);
    //     }
    // }, [policyDocState]);

    useEffect(() => {
        if (policyDocState.status === "failed") {
            setIsLoading(false);
        }
    }, [policyDocState]);

    const fetchDocument = () => {
        // if (isDocumentLoaded) {
        //     setRandomUUId(GetUUId());
        //     return;
        // }
        // else {
        dispatch(resetDocumentState());
        if (policyInfo) {
            logAPI({
                Event: isStarter ? Starter__Document_Type_Log : getLoggingEventName(tabName) + LoggingEvent.DocumentView,
                FileNumber: policyInfo?.file_number,
                PolicyNumber: policyInfo?.policy_no,
                DocumentName: policyInfo?.doc_image_name,
                // FullStreetAddress: directStarter?.property_full_street_address,
                // city: directStarter?.city,
                // state: directStarter?.state,
                // zip: directStarter?.zip,
                // UnitNumber: "",
                // APN: directStarter?.apn,
                // Fips: directStarter?.fips,
                // PolicyNumber: directStarter?.full_policy_number,
                StarterType: isDirectStarter ? Direct_Starter_Type_Log : isReferenceStarter ? Reference_Starter_Type_Log : ""
            });
            setIsLoading(true);
            // setRandomUUId(GetUUId());
            dispatch(getDocument({
                fullPolicyNumber: policyInfo?.policy_no,
                docName: policyInfo?.doc_image_name, rowNo: policyInfo?.row_number, fileNumber: policyInfo?.file_number
            }));
        }
        // }
    }

    const onClose = () => {
        dispatch(resetDocumentState())
        setOpenPdfViewer(false);
        setDocumentLoaded(false);
    }

    const handlePropertyDetailsOpen = (row_number: number) => {
        // setPropertyDetails(!propertyDetailsOpen)
        handlePropertyDetailsClick(row_number);
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Grid container rowSpacing={1.5}>
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3} onClick={() => handlePropertyDetailsOpen(policyInfo?.row_number)}>
                        {isPropertyDetailsOpen ? <KeyboardArrowUpIcon sx={{ color: "#004D82" }} data-testid={policyNumber + "_HeaderClose"} />
                            : <KeyboardArrowDownIcon sx={{ color: "#004D82" }} data-testid={policyNumber + "_HeaderOpen"} />}
                    </Grid>
                }
                <Grid item xs={1.5} md={2} lg={2} xl={1.5} >
                    <PropertyHeader>Policy Effective Date</PropertyHeader>
                </Grid>
                <Grid item xs={1.5} md={2.5} lg={2} xl={1.5}>
                    <Typography data-testid={policyNumber + "_PolicyEffectiveDate"}>{FormatDateField(policyInfo?.policy_effective_date)}</Typography>
                </Grid>
                <Grid item xs={1} md={1.7} lg={1.5} xl={1}>
                    <PropertyHeader>File Number</PropertyHeader>
                </Grid>
                <Grid item xs={1.5} md={5.3} lg={6.2} xl={1.5}>
                    <Typography data-testid={policyNumber + "_FileNumber"}>{policyInfo?.file_number}</Typography>
                </Grid>
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3} xl={0}>

                    </Grid>
                }
                <Grid item xs={1.3} md={2} lg={2} xl={1.3}>
                    <PropertyHeader>Full Policy Number</PropertyHeader>
                </Grid>
                <Grid item xs={1.5} md={2.5} lg={2} xl={1.5}>
                    <Typography data-testid={policyNumber + "_FullPolicyNumber"}>{policyInfo?.policy_no}</Typography>
                </Grid>
                <Grid item xs={1.3} md={1.5} lg={1.5} xl={1.3}>
                    <PropertyHeader>Transaction Type</PropertyHeader>
                </Grid>
                <Grid item xs={1} md={5.5} lg={6.2} xl={1.8}>
                    <Typography data-testid={policyNumber + "_TransactionType"}>{policyInfo?.transaction_type}</Typography>
                </Grid>
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3} xl={0.3}>

                    </Grid>
                }
                <Grid item xs={1} md={1.7} lg={2} xl={1}>
                    <PropertyHeader>Insured Party</PropertyHeader>
                </Grid>
                <Grid item xs={2} md={2.8} lg={2} xl={2}>
                    <Typography data-testid={policyNumber + "_InsuredParty"}>{policyInfo?.insured_party}</Typography>
                </Grid>
                <Grid item xs={1.2} md={2} lg={1.5} xl={1.2}>
                    <PropertyHeader>Insured Amount</PropertyHeader>
                </Grid>
                <Grid item xs={1.3} md={5} lg={6.2} xl={1.3}>
                    <Typography data-testid={policyNumber + "_InsuredAmount"} sx={{ wordBreak: 'break-word' }}>{policyInfo?.insured_amount ? "$" : ""}{policyInfo?.insured_amount ? Number(policyInfo?.insured_amount).toLocaleString() : ""}</Typography>
                </Grid>
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3} xl={0.3}>

                    </Grid>
                }
                <Grid item xs={1} md={2} lg={1.3} xl={1.3}>
                    <PropertyHeader>Policy Source</PropertyHeader>
                </Grid>
                <Grid item xs={1.5} md={2.3} lg={1.5} xl={1.3}>
                    <Typography data-testid={policyNumber + "_PolicySource"}>{policyInfo?.policy_source}</Typography>
                </Grid>
                {
                    policyInfo?.claim_no && !isStarter &&
                    <Grid item xs={2.7} md={6} lg={6.2} xl={3.2}>
                        <Typography data-testid={policyNumber + "_ClaimNo"} sx={{ color: '#E00000', textAlign: "center", fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {policyInfo?.claim_no}</Typography>
                    </Grid>
                }
                {
                    !policyInfo?.claim_no &&
                    policyInfo?.doc_image_id &&
                    policyInfo?.transaction_type !== "U Lender Policy" &&
                    <Grid item xs={3.3} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <DownloadImageButton onClick={fetchDocument} data-testid={policyNumber + "_DocumentImage"} sx={{ color: "#0075AA !important", backgroundColor: "white", maxHeight: "36px" }} variant="outlined" startIcon={<UploadFileIcon />}>
                            Document Image
                        </DownloadImageButton>
                    </Grid>
                }

            </Grid>
            {/* {
                getLength_of_Base64String(pdfDocument.base64String) > DocumentSizeLimit ?
                    <PdfDownload base64String={pdfDocument.base64String} key={count}
                        fileName={policyInfo?.policy_no ? policyInfo?.policy_no + ".pdf" : policyInfo?.doc_image_name ? policyInfo?.doc_image_name + ".pdf" : "PolicyDocument.pdf"}
                        onClose={onClose} />
                    :
                    <PdfViewer key={randomUUId} isOpen={openPdfViewer} pdf={pdfDocument} onClose={onClose} />
            } */}
        </>
    )
}

export default HeaderResult