import React, { ReactElement, useState, useEffect } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import MsalAuthService from './MsalAuthService';

interface MsalInstanceProviderProps {
    children: React.ReactElement;
}

/**
 * Wrapper component for MSAL provider
 * @param {JSX.Element} children
 * @component
 * @returns {JSX.Element}
 */
export default function MsalInstanceProvider({
    children
}: MsalInstanceProviderProps): ReactElement {
    const [msalConfig, setMsalConfig] = useState<any>(null);
    const [msalInstance, setMsalInstance] = useState<any>(null);
    const msalConfigReq = async () => await MsalAuthService.getMsalConfig();

    useEffect(() => {
        if (!msalConfig)
            msalConfigReq().then((res) => {
                setMsalConfig(res);
                setMsalInstance(new PublicClientApplication(res));
            });
    }, [msalConfig]);

    return <>{!!msalInstance && <MsalProvider instance={msalInstance}>{children}</MsalProvider>}</>;
}
