import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { APNSearchForm } from '../../entities/Types';
import { fetchAddressByAPN } from './apnSearchAPI';

export interface APNSearchResponse {
    match_code: string,
    message: string,
    properties: any[];
    formFields: any;
    total_count: number;
}

export interface APNState {
    addresses: APNSearchResponse;
    status: 'idle' | 'loading' | 'failed' | 'reset';
    formFields: APNSearchForm
}
export const resetAPNState = createAction('REVERT_APN_STATE');

const initialState: APNState = {
    addresses: {
        match_code: "",
        message: "",
        properties: [],
        formFields: {},
        total_count: 0,
    },
    status: 'idle',
    formFields: {
        stateField: { id: "", label: "" },
        countyField: { fips: "", county: "" },
        APNField: "",
    }
};

export const searchByAPN = createAsyncThunk(
    'APN/search',
    async (formData: APNSearchForm) => {
        const response = await fetchAddressByAPN(formData);
        return response;
    }
);

export const setAPNToState = createAsyncThunk(
    'APN/setAPNToState',
    async (formData: APNSearchForm) => {
        return formData;
    }
);

export const apnSearchSlice = createSlice({
    name: 'APN',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByAPN.pending, (state) => {
                state.status = 'loading';
                state.addresses.properties = [];
                state.addresses.match_code = "";
                state.addresses.message = "";
            })
            .addCase(searchByAPN.fulfilled, (state, action) => {
                state.status = 'idle';
                if (!action.payload)
                    return;
                state.addresses.properties = action.payload.properties;
                state.addresses.match_code = action.payload.match_code;
                state.addresses.message = action.payload.message;
                state.addresses.total_count = action.payload.total_count ?? 0;
            })
            .addCase(searchByAPN.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setAPNToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = {
                    stateField: action.payload.stateField,
                    countyField: action.payload.countyField,
                    APNField: action.payload.APNField,
                    isWildCardSearch: action.payload.isWildCardSearch,
                }
            })
            .addCase(resetAPNState, () => initialState);
    },
});

export const apnList = (state: RootState) => state.rootReducer.APN;
export default apnSearchSlice.reducer;
