import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { GradientButton } from '../../../StyledComponents/CommonControls';

interface Props {
    isOpen: boolean
    notificationText: string,
    headerGradientStyle: React.CSSProperties,
    fontComponent: React.ReactNode,
    onClose?: React.MouseEventHandler<HTMLElement>;
}

export default function Popup({ isOpen, notificationText, headerGradientStyle, fontComponent, onClose }: Props) {
    const [open, setOpen] = React.useState(isOpen);
    
    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)', borderRadius: '12px' }}>
            <DialogContent sx={{ width: '460px' }}>
                <Box sx={{
                    marginLeft: '10px', marginRight: '10px', position: 'relative', top: '40px', height: '75px',
                    borderRadius: '8px', boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
                }} style={headerGradientStyle} >
                    {fontComponent}
                </Box>
                <Box sx={{ backgroundColor: 'white', padding: '60px 8px 8px 8px', borderRadius: '12px', boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)' }}>
                    <Typography sx={{ color: '#212121', textAlign: 'center', fontSize: '20px', fontWeight: '400', width: '373px', margin: '0 auto' }}>
                        {notificationText}
                    </Typography>
                    <Box sx={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingTop: '17px', paddingBottom: '17px' }}>
                        <GradientButton onClick={onClose} sx={{ color: "white", padding: '6px 16px', textTransform: 'none' }}>Continue</GradientButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    );
}