import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export interface TabState {
    tabName: string;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}

const initialState: TabState = {
    tabName: "",
    status: 'idle',
};

export const setTabToState = createAsyncThunk(
    'Tab/setTabToState',
    async (tabName: string) => {
        return tabName;
    }
);

export const resetTabState = createAction('REVERT_TAB_STATE');

export const tabIdentifierSlice = createSlice({
    name: 'TabIdentifier',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(setTabToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tabName = action.payload
            })
            .addCase(resetTabState, () => initialState);
    },
});

export const tabIdentifier = (state: RootState) => state.rootReducer.Tab;
export default tabIdentifierSlice.reducer;
