import { Box, Typography } from "@mui/material";
import { GradientButton } from "../../components/StyledComponents/CommonControls";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './noContent.module.css';
import { useNavigate } from "react-router-dom";

function NoContent() {
    const navigate = useNavigate();
    const returnToSearch = () => {
        navigate(-1);
    };
    return (
        <>
            <Box className={styles.alignSection}>
                <Typography className={styles.errorCode} component="h2">204</Typography>
                <Typography className={styles.notFoundText} component="h2">No Content</Typography>
                <Typography variant="h6" component="h2">Sorry, we are unable to find the content.</Typography>
                <GradientButton onClick={returnToSearch} size='large' sx={{ color: "white", marginTop: '2%' }}><ArrowBackIcon /> &nbsp; Go Back</GradientButton>
            </Box>
        </>
    )
}

export default NoContent;