import { Configuration, LogLevel } from '@azure/msal-browser';
import config from "../appsettings.json";

export interface AppConfig {
    CLIENT_ID: string;
    TENENT_ID: string;
    AUTHORITY: string;
}

async function getAppConfig(): Promise<AppConfig> {
    try {
        return {
            CLIENT_ID: config.AzureADSettings.CLIENT_ID,
            TENENT_ID: config.AzureADSettings.TENENT_ID,
            AUTHORITY: config.AzureADSettings.AUTHORITY + config.AzureADSettings.TENENT_ID,
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}

const MsalAuthService: {
    getMsalConfig: () => Promise<Configuration>;
} = {
    getMsalConfig: async () => {
        const appConfig = await getAppConfig();
        return {
            auth: {
                clientId: appConfig.CLIENT_ID,
                authority: 'https://login.microsoftonline.com/' + appConfig.TENENT_ID,
                redirectUri: '/',
                postLogoutRedirectUri: '/',
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                //console.info(message);
                                return;
                            case LogLevel.Verbose:
                                //console.debug(message);
                                return;
                            case LogLevel.Warning:
                                //console.warn(message);
                                return;
                            default:
                                return;
                        }
                    },
                },
            }
        }
    }
};
export const loginRequest = {
    scopes: [config.AzureADSettings.SCOPE]
    // scopes: ['offline_access openid profile User.Read api://ea553381-671a-4927-bc0f-6cb09770d3bb/api.policy.download']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export default MsalAuthService;
