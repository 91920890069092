import { Typography, Box } from '@mui/material';

type Props = {
    errorMessageText: string;
};

const ErrorMessage = ({ errorMessageText }: Props) => {
    return (
        <div>
            <Box marginTop={'15px'}>
                <Typography variant="h6" fontWeight={700} fontSize={'20px'}
                    color={"#e00000"}>{errorMessageText}</Typography>
            </Box>
        </div>
    )
}

export default ErrorMessage