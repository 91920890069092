import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import MsalInstanceProvider from './configuration/MsalInstanceProvider';
import Unauthenticated from './features/authentication/Unauthenticated';
import Authenticated from './features/authentication/Authenticated';
import { useEffect } from 'react';
import { getEnvironmentTitle } from './utils/common/commonUtils';

function App() {
  useEffect(() => {
    document.title = getEnvironmentTitle();
  }, [])
  return (
    <MsalInstanceProvider>
      <>
        <AuthenticatedTemplate>
          <div className="App">
            <Authenticated />
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Unauthenticated />
        </UnauthenticatedTemplate>
      </>
    </MsalInstanceProvider>
  );
}

export default App;
