import { Container, Box, Typography } from "@mui/material";
import Footer from "../../layout/footer/Footer";
import Navbar from "../../layout/navbar/navbar";
import { GradientButton } from "../../components/StyledComponents/CommonControls";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './notFound.module.css';
import { useNavigate } from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();
    const returnToSearch = () => {
        navigate(-1); // new line
    };
    return (
        <>
            <Container maxWidth={false}>
                <Navbar />
                <Box className={styles.alignSection}>
                    <Typography className={styles.errorCode} component="h2">404</Typography>
                    <Typography className={styles.notFoundText} component="h2">Not Found</Typography>
                    <Typography variant="h6" component="h2">Sorry, we are unable to find that page.</Typography>
                    <GradientButton onClick={returnToSearch} size='large' sx={{ color: "white", marginTop: '2%' }}><ArrowBackIcon /> &nbsp; Go Back</GradientButton>
                </Box>
                <Box sx={{ position: 'absolute', bottom: '20px' }}>
                    <Footer />
                </Box>
            </Container>
        </>
    )
}

export default NotFound;