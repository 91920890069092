import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox, SectionHeader } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatMailingAddress, formatUnitNumberField, formatZip4Field } from "../../../utils/common/commonUtils";

function PropertyDetail(props: any) {
    const [propertyDetailsOpen, setPropertyDetails] = React.useState(true);
    let propertyDetails: any;
    let overviewData: any;
    if (props && props.propertyDetails) {
        propertyDetails = props.propertyDetails;
        overviewData = props.overview;
    }
    return (
        <div>
            {
                props &&
                propertyDetails &&
                <SectionCard>
                    <SectionHeader onClick={() => setPropertyDetails(!propertyDetailsOpen)} variant="h6">
                        Property Detail &nbsp; {propertyDetailsOpen ? <KeyboardArrowUpIcon data-testid="PropertyDetailClose" />
                            : <KeyboardArrowDownIcon data-testid="PropertyDetailOpen" />}
                    </SectionHeader>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Owner(s)</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="PropertyOwners"><Typography>{overviewData.owner_name}</Typography></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}></Grid>
                            {/* <Grid item xs={1}><PropertyHeader>Vesting</PropertyHeader></Grid>
                            <Grid item xs={3}><Typography>{propertyDetails.vesting}</Typography></Grid> */}
                        </Grid>
                    </SectionDataBox>
                    <Collapse in={propertyDetailsOpen} timeout="auto"
                        unmountOnExit>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Legal Description Details</PropertyHeader></Grid>
                                <Grid item xs={10} data-testid="PropertyLegalDescriptionDetails"><Typography>{overviewData.brief_description}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Property Address</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyFullAddress">{propertyDetails.property_full_address} {formatUnitNumberField(overviewData.property_unit_type.trim(), overviewData.property_unit_number.trim())}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>City, State & ZIP</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="PropertyCityState">{formatMailingAddress("", propertyDetails.city, propertyDetails.state, formatZip4Field(propertyDetails.zip, overviewData.zip4), "")}</Typography></Grid>
                                {/* {propertyDetails.city}{propertyDetails.city && (propertyDetails.state || propertyDetails.zip) ? ", " : ""} {propertyDetails.state} {propertyDetails.zip} */}
                                <Grid item xs={1}><PropertyHeader>County</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyCounty">{propertyDetails.county}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Property Use</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyUse">{propertyDetails.property_use}</Typography>  </Grid>
                                <Grid item xs={1}><PropertyHeader>Subdivision</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="PropertySubdivision">{propertyDetails.lega_subdivision_name}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Parcel Number</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="ParcelNumber">{propertyDetails.apn}</Typography> </Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Mailing Address(s)</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyMailingAddress">{formatMailingAddress(propertyDetails.raw_mailing_full_street_address, propertyDetails.current_owner_mailing_city, propertyDetails.current_owner_mailing_state, formatZip4Field(propertyDetails.current_owner_mailing_zip, propertyDetails.current_owner_mailing_zip4), formatUnitNumberField(overviewData.current_owner_unit_type.trim(), overviewData.current_owner_unit_number.trim()))}</Typography></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1}><PropertyHeader>Census Tract</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="CensusTract">{propertyDetails.property_address_census_tract}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                    </Collapse>
                </SectionCard >
            }
        </div>
    )
}

export default PropertyDetail;