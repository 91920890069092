import { Box, Card, Typography, styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export const PropertyLabel = styled(Typography)`
    font-size:16px;
    font-weight:600;
`;

export const PropertyValue = styled(Typography)`
    font-size:16px;
    font-weight:500;
`;

export const PropertyHeader = styled(Typography)`
    font-weight: 700;
    word-wrap: break-word;
`;

export const SectionHeader = styled(Typography)`
    display: flex;
    align-items: center;
    padding: 25px 0px;
    font-weight: 700;
    color:#004d82;
    font-size:19px;
    cursor: pointer;
`;

export const SectionCard = styled(Card)`
    box-shadow: none;
`;

export const SectionDataBox = styled(Box)`
    border: 1px solid #e5e5e5!important ;
    padding: 15px;
    box-shadow: none;
`;

export const DownloadImageButton = styled(LoadingButton)`
    border: 1px solid;
    font-weight: 600;
    color: #0075aa;
    margin-left: 10px;
    letter-spacing: normal;
`;
