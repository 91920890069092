import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from "../../assets/images/logo/StewartLogo_White.png";
import styles from './navbar.module.css';
import UserAccount from '../userAccount/UserAccount';
import { Link } from '@mui/material';

function Navbar(props: any) {
  return (
    <AppBar position="static" className={styles.navbar}>
      <Toolbar disableGutters sx={{ minHeight: '80px !important' }}>
        <Link href="/" sx={{ display: 'contents' }}>
          <img alt="StewartLogo" className={styles.logoAlign} height='25px' src={logo} />
        </Link>
        {props && !props.isLogin &&
          < UserAccount />
        }
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;



