interface StateListType {
    id: string;
    label: string;
}

export const stateListArray: StateListType[] = [
    { id: "AL", label: "AL" },
    { id: "AK", label: "AK" },
    { id: "AZ", label: "AZ" },
    { id: "AR", label: "AR" },
    { id: "CA", label: "CA" },
    { id: "CO", label: "CO" },
    { id: "CT", label: "CT" },
    { id: "DE", label: "DE" },
    { id: "DC", label: "DC" },
    { id: "FL", label: "FL" },
    { id: "GA", label: "GA" },
    { id: "HI", label: "HI" },
    { id: "ID", label: "ID" },
    { id: "IL", label: "IL" },
    { id: "IN", label: "IN" },
    { id: "IA", label: "IA" },
    { id: "KS", label: "KS" },
    { id: "KY", label: "KY" },
    { id: "LA", label: "LA" },
    { id: "ME", label: "ME" },
    { id: "MD", label: "MD" },
    { id: "MA", label: "MA" },
    { id: "MI", label: "MI" },
    { id: "MN", label: "MN" },
    { id: "MS", label: "MS" },
    { id: "MO", label: "MO" },
    { id: "MT", label: "MT" },
    { id: "NE", label: "NE" },
    { id: "NV", label: "NV" },
    { id: "NH", label: "NH" },
    { id: "NJ", label: "NJ" },
    { id: "NM", label: "NM" },
    { id: "NY", label: "NY" },
    { id: "NC", label: "NC" },
    { id: "ND", label: "ND" },
    { id: "OH", label: "OH" },
    { id: "OK", label: "OK" },
    { id: "OR", label: "OR" },
    { id: "PA", label: "PA" },
    { id: "RI", label: "RI" },
    { id: "SC", label: "SC" },
    { id: "SD", label: "SD" },
    { id: "TN", label: "TN" },
    { id: "TX", label: "TX" },
    { id: "UT", label: "UT" },
    { id: "VT", label: "VT" },
    { id: "VA", label: "VA" },
    { id: "WA", label: "WA" },
    { id: "WV", label: "WV" },
    { id: "WI", label: "WI" },
    { id: "WY", label: "WY" }
]