import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import photo1 from "../../assets/images/login/Photo-1.jpg";
import photo2 from "../../assets/images/login/Photo-2.jpg";
import photo3 from "../../assets/images/login/Photo-3.jpg";
import photo4 from "../../assets/images/login/Photo-4.jpg";
import photo5 from "../../assets/images/login/Photo-5.jpg";
import photo6 from "../../assets/images/login/Photo-6.jpg";

function LoginImageSection() {
    const [backgroundImage, setBackgroundImage] = useState(photo1);

    useEffect(() => {
        var imageId = Math.floor(6 * Math.random()) + 1;
        switch (imageId) {
            case 1: {
                setBackgroundImage(photo1);
                break;
            }
            case 2: {
                setBackgroundImage(photo2);
                break;
            }
            case 3: {
                setBackgroundImage(photo3);
                break;
            }
            case 4: {
                setBackgroundImage(photo4);
                break;
            }
            case 5: {
                setBackgroundImage(photo5);
                break;
            }
            case 6: {
                setBackgroundImage(photo6);
                break;
            }
            default: {
                setBackgroundImage(photo1);
                break;
            }
        }
    }, []);
    return (
        <Grid item xs={false} sm={4} md={6} sx={{
            backgroundImage: 'url(' + backgroundImage + ')', backgroundSize: 'cover'
        }}>
        </Grid>
    )
}

export default LoginImageSection