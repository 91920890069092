import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  .Mui-disabled {
    background-color: #f5f5f5 !important;
    color:#BDBDBD !important
  }
  .Mui-disabled .MuiFormLabel-asterisk {
    color: #BDBDBD!important;
  }
  .MuiFormLabel-asterisk {
    font-size:18px;
    font-weight:900;
  }
`;