import MatchCodes from '../../../utils/enums/MatchCodes';
import { Typography, Box } from '@mui/material';

type Props = {
    matchCode: string;
    matchText: string;
    resultText?: string;
};

const MatchStatus = ({ matchCode, matchText, resultText }: Props) => {
    return (
        <div>
            <Box marginTop={'15px'}>
                <Typography variant="h6" fontWeight={700} fontSize={'20px'}
                    color={matchCode === MatchCodes.INVALID_ADDRESS.toString() ||
                        matchCode === MatchCodes.INVALID_APN.toString() ? '#e00000' : '#7F7F7F'}>{matchText}</Typography>
                <Typography
                    color={matchCode === MatchCodes.INVALID_ADDRESS.toString() ||
                        matchCode === MatchCodes.INVALID_APN.toString() ? '#e00000' : '#7F7F7F'} sx={{ paddingTop: '5px' }}>
                    {resultText}</Typography>
            </Box>
        </div>
    )
}

export default MatchStatus