import { useEffect, useState } from 'react';
import { axiosSecuredInstance } from './axiosConfig';
import Exception from '../components/ui/notification/exception/Exception';
import { useLocation, useNavigate } from 'react-router';

const AxiosErrorHandler: React.FC<{ children: any }> = ({ children }) => {
  const [isError, setException] = useState(false);
  const [ErrorRequest, setErrorRequest] = useState<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onExceptionClose = () => {
    setException(false);
    if (pathname?.includes('parcel-detail') && !ErrorRequest?.config?.url.includes('/documents')) {
      navigate(-1);
    }
  }

  const TriggerException = (error: any) => {
    // if (error?.response?.status) {
    setException(true);
    setErrorRequest(error.response);
    // }
  }

  useEffect(() => {
    const requestInterceptor = axiosSecuredInstance.interceptors.request.use((request) => {
      return request;
    }, (error) => {
      TriggerException(error);
      return Promise.reject(error);
    });

    const responseInterceptor = axiosSecuredInstance.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      TriggerException(error);
      return Promise.reject(error);
    });

    return () => {
      axiosSecuredInstance.interceptors.request.eject(requestInterceptor);
      axiosSecuredInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [isError]);

  useEffect(() => {
    const handlePopstate = () => {
      setException(false);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return <>
    {children}
    <Exception isOpen={isError} onClose={onExceptionClose} />
  </>;
};

export default AxiosErrorHandler;