import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox, SectionHeader } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { convertToPercent } from "../../../utils/common/commonUtils";

function AssessementDetail(props: any) {
    const [assesmentTaxOpen, setAssesment] = React.useState(false);
    let assessmentDetails: any;
    if (props && props.assessmentDetails) {
        assessmentDetails = props.assessmentDetails;
    }
    return (<div>
        {
            props &&
            props.assessmentDetails &&
            assessmentDetails &&
            <SectionCard>
                <SectionHeader onClick={() => setAssesment(!assesmentTaxOpen)} variant="h6">
                    Assessment & Taxes &nbsp; {assesmentTaxOpen ? <KeyboardArrowUpIcon data-testid="AssessmentDetailClose" />
                        : <KeyboardArrowDownIcon data-testid="AssessmentDetailOpen" />}
                </SectionHeader>
                <SectionDataBox>
                    <Grid container>
                        <Grid item xs={2}><PropertyHeader>Assessment Year</PropertyHeader></Grid>
                        <Grid item xs={3} data-testid="AssessmentYear"><Typography>{assessmentDetails.assessment_year}</Typography> </Grid>
                        <Grid item xs={1}><PropertyHeader>Tax Year</PropertyHeader></Grid>
                        <Grid item xs={2} data-testid="TaxYear"><Typography>{assessmentDetails.tax_year}</Typography></Grid>
                        <Grid item xs={1}><PropertyHeader>Tax Exemption</PropertyHeader></Grid>
                        <Grid item xs={3} data-testid="TaxExemption"><Typography>{assessmentDetails.tax_exemtion}</Typography></Grid>
                    </Grid>
                </SectionDataBox>
                <Collapse in={assesmentTaxOpen} timeout="auto"
                    unmountOnExit>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Total Assessed Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TotalAssessedValue"><Typography>{assessmentDetails.assessment_total_value ? "$" + Number(assessmentDetails.assessment_total_value).toLocaleString() : ""}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Amount</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="TaxAmount"><Typography>{assessmentDetails.tax_amount ? "$" + Number(assessmentDetails.tax_amount).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Rate Area</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxRateArea"><Typography>{assessmentDetails.tax_rate_code_area}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Land Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="LandValue"><Typography>{assessmentDetails.assesment_land_value ? "$" + Number(assessmentDetails.assesment_land_value).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Account Id</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxAccountId"><Typography>{assessmentDetails.tax_account_id}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Improvement Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="ImprovementValue"><Typography>{assessmentDetails.assessment_improvement_value ? "$" + Number(assessmentDetails.assessment_improvement_value).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Tax Status</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="TaxStatus"><Typography>{assessmentDetails.tax_status}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Improvement Ratio</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="ImprovementRatio"><Typography>{convertToPercent(assessmentDetails.improvement_ratio) ? convertToPercent(assessmentDetails.improvement_ratio) + "%" : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Delinquent Tax Year</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="DelinquentTaxYear"><Typography>{assessmentDetails.tax_deliquent_year}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Market Value Year</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketValueYear"><Typography>{assessmentDetails.market_value_year}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Market Land Value</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketLandValue"><Typography>{assessmentDetails.market_value_land ? "$" + Number(assessmentDetails.market_value_land).toLocaleString() : ""}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Market Improvement</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="MarketImprovement"><Typography>{assessmentDetails.market_improvement ? "$" + Number(assessmentDetails.market_improvement).toLocaleString() : ""}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Market Total</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="MarketTotal"><Typography>{assessmentDetails.market_total_value ? "$" + Number(assessmentDetails.market_total_value).toLocaleString() : ""}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                </Collapse>
            </SectionCard>
        }
    </div>)
}

export default AssessementDetail;