import React, { useEffect, useState } from "react";
import ShowPdf from "./ShowPdf";
import * as UIModel from "../../../entities/UIModel";

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement;
//     },
//     ref: React.Ref<unknown>,
// ) {
//     return <Zoom ref={ref} {...props} />;
// });

type Props = {
    isOpen: boolean;
    pdf: UIModel.PdfDocument;
    randomId: string
    onClose?: any;
};

const PdfViewer = ({ isOpen = false, pdf, onClose, randomId }: Props) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    // const unloadPopout = () => {
    //     onClose();
    // };

    if (open) {
        return (
            <ShowPdf randomId={randomId} pdf={pdf} />
        )
    }
    return null;
}

export default PdfViewer