import { combineReducers } from "@reduxjs/toolkit"
import addressReducer from "../features/addressSearch/addressSearchSlice" //"./ ./reducers/addressSlice"
import parcelDetails from "../features/parcelDetails/parcelDetailsSlice"
import policyDoc from "../common/Slice/policyDocumentSlice"
import CountyListData from "../features/apn-search/getCounty/getCountySlice"
import apnList from "../features/apn-search/apnSearchSlice"
import tabIdentifier from "../components/ui/propertyTable/tabIdentifierSlice"
import PolicySearchData from "../features/policySearch/policySearchSlice"
import FeatureFlagData from "../common/Slice/featureFlagSlice"
import LegalSearchData from "../features/legalSearch/legalSearchForm/LegalSearchSlice"

const rootReducer = combineReducers({
    address: addressReducer,
    parcelDetails: parcelDetails,
    policyDocument: policyDoc,
    CountyList: CountyListData,
    PolicySearchList: PolicySearchData,
    LegalSearchList: LegalSearchData,
    FeatureFlag: FeatureFlagData,
    APN: apnList,
    Tab: tabIdentifier,
})

export default rootReducer