import { useEffect } from "react";
import * as UIModel from "../../../entities/UIModel";

type Props = {
    pdf: UIModel.PdfDocument;
    randomId: string
};
const ShowPdf = ({ pdf, randomId }: Props) => {
    // const pdfBlob = `data:application/pdf;headers=filename%3D12345;base64,${pdf.base64String}`;
    // window.open("data:application/octet-stream;charset=utf-16le;base64,"+data64);
    // useEffect(() => {
    //     window.open("data:application/pdf;charset=utf-16le;base64," + pdf.base64String);
    //     // window.open(pdfBlob);
    // }, [pdfBlob]);

    useEffect(() => {
        // Open PDF in a new window
        const win = window.open('', randomId, 'width=1200,height=800,left=200,top=200');
        // const win = window.open("", "");
        if (win) {
            win.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${pdf.base64String}'></iframe>`);
        } else {
            // Opening new window was blocked
            console.error("Popup blocked. Please allow popups to view the PDF.");
        }
    }, [pdf.base64String]); // pdf.base64String directly used in dependency array


    return (
        <></>
        // <div>
        //     <object data={pdfBlob} type="application/pdf" width="100%" height="100%">
        //         Your browser does not support embedded PDFs.
        //     </object>
        // </div>
        // <div style={{
        //     width: "100%",
        //     height: "100%",
        // }}  />
    )
}

export default ShowPdf