import BingMapsReact from "./BingMapsReact";
import { useState } from "react";

interface Props {
    lat: number,
    long: number
}

export default function BingMap({ lat, long }: Props) {
    const [mapReady, setMapReady] = useState(false);
    return (
        <div>
            <BingMapsReact
                lat={lat}
                long={long}
                bingMapsKey="ArYikaA_Nwglc0s9yyWeYBxzwaSrlOS85IbF_fD5CfsDNNPQ1BmCEtr2vVWB7rru"
                onMapReady={() => {
                    setMapReady(true);
                }}
                height={'475px'}
                width={'100%'}
            />
        </div>
    );
}
