import { Typography, Box } from '@mui/material';

type Props = {
    headerText: string;
};

const TabHeader = ({ headerText }: Props) => {
    return (
        <Box>
            <Typography color='#004D82' fontSize='20px' fontWeight='700'>{headerText}</Typography>
        </Box>
    )
}

export default TabHeader