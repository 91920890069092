import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchFeatureFlagList } from '../Api/featureFlagAPI';
import { FeatureFlagResponse } from '../../entities/ApiModel/FeatureFlagResponse';
import { RootState } from '../../app/store';
// import { RootState } from '../../../app/store';
// import { fetchCountyList } from './getCountyAPI';

// export interface FlagList {
//     match_code: string,
//     message: string,
//     properties: {},
// }

// export interface CountyResponse {
//     match_code: string,
//     message: string,
//     county_list: CountyList;
// }

// export interface FeatureFlag {
//     LegalSearch: {
//         enabled: boolean;
//         conditions: {
//             client_filters: any[];
//         };
//     };
//     PolicySearch: {
//         enabled: boolean;
//         conditions: {
//             client_filters: any[];
//         };
//     };
// }

export interface FeatureFlagState {
    featureflag: FeatureFlagResponse
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetFeatureFlagState = createAction('REVERT_FEATURE_FLAG_STATE');

const initialState: FeatureFlagState = {
    featureflag: {
        match_code: "",
        message: "",
        featureflag: []
        // {
        //     AddressSearch: { enabled: false, conditions: { client_filters: [] } },
        //     APNSearch: { enabled: false, conditions: { client_filters: [] } },
        //     PolicySearch: { enabled: false, conditions: { client_filters: [] } },
        //     LegalSearch: { enabled: false, conditions: { client_filters: [] } },
        //     AssesementLegalSearch: { enabled: false, conditions: { client_filters: [] } },
        //     PolicyLegalSearch: { enabled: false, conditions: { client_filters: [] } }
        // }
    },
    status: 'idle',
};

export const getFeatureFlagList = createAsyncThunk(
    'Flag/getFeatureFlag',
    async () => {
        const response = await fetchFeatureFlagList();
        return response;
    }
);

export const getFeatureFlagSlice = createSlice({
    name: 'FeatureFlag',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeatureFlagList.pending, (state) => {
                state.status = 'loading';
                state.featureflag = {
                    match_code: "",
                    message: "",
                    featureflag: []
                    // {
                    //     AddressSearch: { enabled: false, conditions: { client_filters: [] } },
                    //     APNSearch: { enabled: false, conditions: { client_filters: [] } },
                    //     PolicySearch: { enabled: false, conditions: { client_filters: [] } },
                    //     LegalSearch: { enabled: false, conditions: { client_filters: [] } },
                    //     AssesementLegalSearch: { enabled: false, conditions: { client_filters: [] } },
                    //     PolicyLegalSearch: { enabled: false, conditions: { client_filters: [] } }
                    // }
                }
            })
            .addCase(getFeatureFlagList.fulfilled, (state, action) => {
                state.status = 'idle';
                if (!action.payload)
                    return;
                state.featureflag.match_code = action.payload.match_code;
                state.featureflag.message = action.payload.message;
                state.featureflag.featureflag = action.payload.featureflag;
            })
            .addCase(getFeatureFlagList.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetFeatureFlagState, () => initialState);
    },
});

export const FeatureFlagData = (state: RootState) => state.rootReducer.FeatureFlag;
export default getFeatureFlagSlice.reducer;
