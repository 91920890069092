import React, { useEffect, useState } from 'react'
import Login from './Login';
import InvalidAccess from './InvalidAccess';

function Unauthenticated() {
    const [isLogin, setLogin] = useState(false);
    const [isInvalidAccess, setInvalidAccess] = useState(false);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname.includes("InvalidAccess")) {
            setLogin(false);
            setInvalidAccess(true);
        }
        else {
            setLogin(true);
        }
    }, [])

    return (
        <>
            {
                isLogin &&
                <Login />
            }
            {
                isInvalidAccess &&
                <InvalidAccess />
            }
        </>
    )
}

export default Unauthenticated