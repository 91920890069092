import axios from "axios";
import config from "../appsettings.json";
import { PublicClientApplication } from "@azure/msal-browser";
import MsalAuthService, { loginRequest } from "./MsalAuthService";
import { v4 as uuidv4 } from "uuid";

const axiosAnonymousInstance = axios.create({
    baseURL: config.ApiSettings.API_URL,
    responseType: "json",
    headers: {
        'content-type': 'application/json',
    },
});

const axiosSecuredInstance = axios.create({
    baseURL: config.ApiSettings.API_URL,
    responseType: "json",
    headers: {
        'content-type': 'application/json',
        'X-Correlation-ID': uuidv4()
    },
});

let msalInstance: any = null;
const initializeMsal = async () => {
    if (!msalInstance) {
        const msalConfigReq = await MsalAuthService.getMsalConfig();
        msalInstance = new PublicClientApplication(msalConfigReq);
        await msalInstance.initialize();
    }
};

// Initialize MSAL
initializeMsal().catch(error => {
    console.error("MSAL initialization error:", error);
});

axiosSecuredInstance.interceptors.request.use(
    async (config) => {
        // const msalConfigReq = await MsalAuthService.getMsalConfig();
        // let instance = new PublicClientApplication(msalConfigReq);
        // Ensure MSAL is initialized
        await initializeMsal();

        let account = msalInstance.getAllAccounts()[0];
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const accessTokenRequest = {
            ...loginRequest,
            account: account,
        };

        await msalInstance.acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse: any) => {
                let accessToken = accessTokenResponse?.accessToken;
                const token = `Bearer ${accessToken}`;
                config.headers.Authorization = token;
            }).catch((error: any) => {
                console.log(error);
                window.location.href = "/login";
            });
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosAnonymousInstance, axiosSecuredInstance };
