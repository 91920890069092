import {
    Container,
    Box,
    Typography
} from "@mui/material";
import Footer from "../../layout/footer/Footer";
import Navbar from "../../layout/navbar/navbar";
import { GradientButton } from "../../components/StyledComponents/CommonControls";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './accessDenied.module.css';
import { useNavigate } from "react-router-dom";

function AccessDenied() {
    const navigate = useNavigate();
    const returnToSearch = () => {
        navigate(-1);
    };
    return (
        <>
            <Container maxWidth={false}>
                <Navbar />
                <Box className={styles.alignSection}>
                    <Typography className={styles.errorCode} component="h2">403</Typography>
                    <Typography className={styles.notFoundText} component="h2">Access Denied</Typography>
                    <Typography variant="h6" component="h2">You do not have permission to access the requested page</Typography>
                    <GradientButton onClick={returnToSearch} size='large' sx={{ color: "white", marginTop: '2%' }}><ArrowBackIcon /> &nbsp; Go Back</GradientButton>
                </Box>
                <Box sx={{ position: 'absolute', bottom: '20px' }}>
                    <Footer />
                </Box>
            </Container>
        </>
    )
}

export default AccessDenied;