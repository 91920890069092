import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { fetchCountyList } from './getCountyAPI';

export interface CountyList {
    fips: string,
    county: string,
}

export interface CountyResponse {
    match_code: string,
    message: string,
    county_list: CountyList;
}

export interface CountyState {
    county: CountyResponse;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetCountyState = createAction('REVERT_COUNTY_STATE');

const initialState: CountyState = {
    county: {
        match_code: "",
        message: "",
        county_list: { fips: "", county: "" }
    },
    status: 'idle',
};

export const getCountyByStateCode = createAsyncThunk(
    'APN/getCounty',
    async (stateCode: string) => {
        const response = await fetchCountyList(stateCode);
        return response;
    }
);

export const getCountySlice = createSlice({
    name: 'County',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountyByStateCode.pending, (state) => {
                state.status = 'loading';
                state.county.county_list = { fips: "", county: "" }
                state.county.match_code = "";
                state.county.message = "";
            })
            .addCase(getCountyByStateCode.fulfilled, (state, action) => {
                state.status = 'idle';
                if (!action.payload)
                    return;
                state.county.county_list = action.payload.county_list;
                state.county.match_code = action.payload.match_code;
                state.county.message = action.payload.message;
            })
            .addCase(getCountyByStateCode.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetCountyState, () => initialState);
    },
});

export const CountyListData = (state: RootState) => state.rootReducer.CountyList;
export default getCountySlice.reducer;
