import React, { useEffect } from 'react'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import { PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExceptionText from './PolicyExceptionText';

interface Props {
    policyInfo: any,
    exceptionInfo: any,
    isPropertyDetailsOpen: boolean,
    isStarter?: Boolean,
}

const CollapseHeader = ({ policyInfo, exceptionInfo, isPropertyDetailsOpen, isStarter }: Props) => {
    const [exceptionOpen, setexceptionOpen] = React.useState(false);
    const [exceptionFirstLine, setExceptionLine] = React.useState(false);
    let policyNumber = policyInfo?.policy_no;

    function removeParagraphTag(htmlString: string) {
        htmlString = htmlString.replace(/^\s*<p>\s*/, '');
        htmlString = htmlString.replace(/\s*<\/p>\s*$/, '');
        return htmlString;
    }

    useEffect(() => {
        if (exceptionOpen)
            setExceptionLine(exceptionOpen);
    }, [exceptionOpen]);

    const CollapseEnd = () => {
        setExceptionLine(exceptionOpen)
    }

    const CollapseEntered = () => {
        setExceptionLine(exceptionOpen)
    }

    return (
        <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
            <Grid container rowSpacing={1.5} sx={{ marginTop: '14px' }}>
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3}>

                    </Grid>
                }
                {/* {
                    policyInfo?.transaction_type === "Owner Policy" &&
                    <> */}
                <Grid item xs={0.7} md={1.2} lg={1}>
                    <PropertyHeader>Grantor</PropertyHeader>
                </Grid>
                <Grid item xs={4} md={3.2} lg={4}>
                    <Typography data-testid={policyNumber + "_Grantor"}>{policyInfo?.grantor}</Typography>
                </Grid>
                <Grid item xs={0.7} md={1} lg={1}>
                    <PropertyHeader>Grantee</PropertyHeader>
                </Grid>
                <Grid item xs={2} md={6.1} lg={5.7}>
                    <Typography data-testid={policyNumber + "_Grantee"}>{policyInfo?.grantee}</Typography>
                </Grid>
                {/* </> */}
                {/* } */}

                {/* <Grid item xs={1} md={2} lg={1.5}>
                    <PropertyHeader>Policy Source</PropertyHeader>
                </Grid>
                <Grid item xs={1.5} md={2.6} lg={2.2}>
                    <Typography>{policyInfo?.policy_source}</Typography>
                </Grid> */}
                {!isStarter &&
                    <Grid item xs={0.3} md={0.4} lg={0.3}>

                    </Grid>
                }
                <Grid item xs={0.4} md={0.5} lg={0.4} onClick={() => setexceptionOpen(!exceptionOpen)}>
                    {exceptionOpen ? <KeyboardArrowUpIcon sx={{ color: "#004D82" }} />
                        : <KeyboardArrowDownIcon sx={{ color: "#004D82" }} />}
                </Grid>
                <Grid item xs={0.7} md={1.5} lg={1.2}>
                    <PropertyHeader>Exceptions</PropertyHeader>
                </Grid>
                <Grid item xs={10.6} md={9.6} lg={10.1}>
                    {
                        policyInfo?.claim_no &&
                        <Typography>
                            Exception data not available due to claim on the policy
                        </Typography>
                    }
                    {
                        !policyInfo?.claim_no && (!exceptionInfo || exceptionInfo.length <= 0 || !exceptionInfo[0]?.exceptions) ?
                            <Typography>N/A</Typography> : ""
                    }
                    {
                        !policyInfo?.claim_no && exceptionInfo && exceptionInfo.length > 0 && exceptionInfo[0]?.exceptions &&
                        <>
                            {!exceptionFirstLine &&
                                <ExceptionText sequenceNumber={exceptionInfo[0]?.exceptions_sequence_number}
                                    exceptionText={
                                        exceptionInfo && exceptionInfo.length > 0
                                            ? exceptionInfo[0]?.exceptions
                                                ? `${removeParagraphTag(exceptionInfo[0]?.exceptions)?.slice(0, 150)
                                                }...`
                                                : "N/A"
                                            : "N/A"
                                    }
                                />
                            }
                            {
                                <Collapse in={exceptionOpen} onEntered={CollapseEntered} onExited={CollapseEnd} orientation='vertical' timeout="auto" unmountOnExit>
                                    {
                                        exceptionInfo?.map((exception: any, index: any) => (
                                            <Box sx={{ paddingBottom: "15px" }}>
                                                <ExceptionText sequenceNumber={exception.exceptions_sequence_number}
                                                    exceptionText={
                                                        exception && exception.exceptions_sequence_number
                                                            ? `${removeParagraphTag(exception.exceptions)}`
                                                            : ""
                                                    }
                                                />
                                            </Box>
                                        ))
                                    }
                                </Collapse>
                            }
                        </>
                    }
                </Grid>
            </Grid>
        </Collapse >
    )
}

export default CollapseHeader