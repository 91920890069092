import { Box, Grid, Typography } from "@mui/material"
import { ClearButton, GradientButton, GridItem } from "../../../components/StyledComponents/CommonControls"
import { StyledTextField } from "../../../components/StyledComponents/StyledTextField"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { StyledAutocomplete } from "../../../components/StyledComponents/StyledAutocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stateListArray } from "../../../utils/constants/StateListConstant";
import { useEffect, useRef, useState } from "react";
import styles from '../legalSearch.module.css';
import { AssesementSearchFormType } from "../../../entities/Types/AssesementSearchFormType";
import { CountyListData, getCountyByStateCode } from "../../apn-search/getCounty/getCountySlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingProgressModel from "../../../components/ui/circularProgress/LoadingProgress";

function AssessmentSearchForm() {
    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    let countyRef: any;
    const dispatch = useAppDispatch();
    const countyListState = useAppSelector(CountyListData);
    const rerenderRef = useRef(0);

    const defaultValues: DefaultValues<AssesementSearchFormType> = {
        stateField: { id: "", label: "" },
        countyField: { county: "", fips: "" },
        LotFrom: "",
        LotTo: "",
        Block: "",
        District: "",
        Unit: "",
        SubDivision: "",
        PhaseNumber: "",
        TractNumber: "",
    };
    const LegalSearchValidationSchema = yup.object().shape({

    });
    const form = useForm<AssesementSearchFormType>({
        defaultValues,
        // resolver: yupResolver(LegalSearchValidationSchema)
    });
    const { register, handleSubmit, setValue, resetField, reset, watch, clearErrors, getValues, setError, formState: { errors }, control } = form;

    const getCountyList = (event: any, value: any) => {
        if (value && value.id) {
            setCounty([]);
            resetField("countyField")
            const state = value.id.trim().toUpperCase();
            setSelectedState(state);
            dispatch(getCountyByStateCode(state))
        }
    }

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    useEffect(() => {
        if (countyListState.status === "loading")
            setLoading(true);
        else
            setLoading(false)

        if (countyListState && countyListState.county.match_code === "1") {
            rerenderRef.current += 1;
            setCounty(countyListState.county.county_list);
        }
        // && (selectedState || formFields?.state?.id)) {

        // if (formFields && formFields?.state?.id && formFields?.fips?.fips) {
        //     setDisabled(false);
        // }

        // setCountyDisabled(false);
        setStateValue(getValues("stateField.id"));

    }, [countyListState]);

    useEffect(() => {
        if (stateValue) {
            countyRef.focus();
        }
    }, [rerenderRef.current]);

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box>
                <form noValidate>
                    <Grid container>
                        <Grid item xs={1}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <Controller
                                    name="stateField"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            {...register("stateField")}
                                            options={stateListArray}
                                            disableClearable
                                            getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                            noOptionsText={"No state"}
                                            renderInput={(params) => (
                                                <StyledTextField
                                                    {...params}
                                                    error={!!fieldState.error}
                                                    autoFocus={true}
                                                    label="State"
                                                    required
                                                    variant="outlined"
                                                    data-testid="StateField"
                                                />
                                            )}
                                            onChange={(_, data) => { field.onChange(data); getCountyList(_, data) }}
                                            data-testid="StateAutocomplete"
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.7}>
                            <GridItem>
                                <Controller
                                    name="countyField"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            disabled={isDisabled}
                                            openOnFocus
                                            {...register("countyField")}
                                            options={countyList}
                                            disableClearable
                                            noOptionsText={"No such county"}
                                            getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            onChange={(_, data) => { field.onChange(data); }} //handleCountyChange() }}
                                            renderInput={(params) => (
                                                <StyledTextField
                                                    {...params}
                                                    inputRef={input => {
                                                        countyRef = input;
                                                    }}
                                                    error={!!fieldState.error}
                                                    label="County"
                                                    required
                                                    variant="outlined"
                                                    data-testid="CountyField"
                                                />
                                            )}
                                            data-testid="CountyAutocomplete"
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('LotFrom')}
                                    // error={errors.APNField && errors.APNField.message !== "" ? true : false}
                                    id="LotFrom"
                                    // inputRef={input => {
                                    //     apnRef = input;
                                    // }}
                                    // InputLabelProps={{
                                    //     shrink: !!apnFieldWatch || apnFieldFocused
                                    // }}
                                    // onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    data-testid="LotFrom"
                                    disabled={isDisabled}
                                    fullWidth label="Lot From" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <RemoveIcon sx={{ color: '#7F7F7F', fontSize: 18 }} />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('LotTo')}
                                    id="LotTo"
                                    data-testid="LotTo"
                                    disabled={isDisabled}
                                    fullWidth label="Lot To" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('Block')}
                                    id="Block"
                                    data-testid="Block"
                                    disabled={isDisabled}
                                    fullWidth label="Block" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.2}>
                            <GridItem>
                                <StyledTextField
                                    {...register('District')}
                                    id="District"
                                    data-testid="District"
                                    disabled={isDisabled}
                                    fullWidth label="District" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('Unit')}
                                    id="Unit"
                                    data-testid="Unit"
                                    disabled={isDisabled}
                                    fullWidth label="Unit" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.7}>
                            <GridItem>
                                <StyledTextField
                                    {...register('SubDivision')}
                                    id="SubDivision"
                                    data-testid="SubDivision"
                                    disabled={isDisabled}
                                    fullWidth label="Subdivision" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('PhaseNumber')}
                                    id="PhaseNumber"
                                    data-testid="PhaseNumber"
                                    disabled={isDisabled}
                                    fullWidth label="Phase Number" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.2}>
                            <GridItem>
                                <StyledTextField
                                    {...register('TractNumber')}
                                    id="TractNumber"
                                    data-testid="TractNumber"
                                    disabled={isDisabled}
                                    fullWidth label="Tract Number" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                            <GridItem sx={{ textAlign: 'left' }}>
                                <GradientButton type="button" size="large" data-testid="SearchButton" variant="contained" className={styles.buttonPadding} startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                    <Typography>Search</Typography>
                                </GradientButton>
                                <ClearButton type="reset" size="large" data-testid="ClearButton" className={styles.buttonPadding} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ClearIcon sx={{ color: '#0075AA', fontSize: 25 }} />}>
                                    <Typography>Clear</Typography>
                                </ClearButton>
                            </GridItem>
                        </Grid>
                    </Grid>
                </form>
            </Box >
        </>
    )
}

export default AssessmentSearchForm