import logAPI from "../../../common/Api/logAPI";
import { axiosSecuredInstance } from "../../../configuration/axiosConfig";
import { LegalSearchRequest } from "../../../entities/ApiModel/LegalSearchRequest";
import { PolicyLegalSearch_Logging_Event } from "../../../utils/constants/constants";
// import logAPI from "../../common/Api/logAPI";
// import { axiosSecuredInstance } from "../../configuration/axiosConfig";
// import { SearchLogResult } from "../../utils/common/commonUtils";
// import { PolicySearch_Loggin_Event } from "../../utils/constants/constants";

export const fetchByLegalSearch = async (request: LegalSearchRequest) => {
    // if (!policyNumber) {
    //     return;
    // }
    let config = {
        method: 'post',
        url: 'api/v1/policylegal/search',
        data: JSON.stringify(request)
    };
    const response: any = (await axiosSecuredInstance.request(config)).data;
    logAPI({
        Event: PolicyLegalSearch_Logging_Event.Legal_Search_Event,
        UserInput: request,
        SearchResult: response?.message
    });
    return response;
};