import { axiosSecuredInstance } from '../../configuration/axiosConfig';

const logAPI = async (apiLog: any) => {
    if (!apiLog) {
        return;
    }
    const postData = { Username: "", DateTime: new Date(), ...apiLog };
    try {
        let config = {
            method: 'post',
            url: 'api/logs',
            data: JSON.stringify(postData)
        };
        try {
            axiosSecuredInstance.request(config);
        } catch (e: any) {
            throw new Error(e.message);
        }
    } catch (error) {
    }
};

export default logAPI;
