import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

type Props = {
  base64String: string;
  fileName: string;
  // key: number
  policyNumber?: string;
  onClose?: any;
};
const PdfDownload = ({ base64String, fileName, onClose, policyNumber }: Props) => {

  const [open, setOpen] = useState(true);
  const downloadFile = () => {
    if (!base64String)
      return;
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
  }

  useEffect(() => {
    downloadFile();
    onClose();
  }, []);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 3000); // Adjust the duration (3000ms = 3 seconds)

      // Clear the timeout if the component unmounts or if `open` changes
      return () => clearTimeout(timer);
    }
  }, [open, setOpen]);

  return (
    <>
      <Collapse in={open}>
        <Alert
          variant='filled'
          sx={{
            mb: 2,
            position: 'fixed',
            bottom: '20px',
            right: '20px', // Adjust the distance from the right
            zIndex: 9999, // Ensure it's above other elements
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          The selected policy {policyNumber} is being downloaded to your PC
        </Alert>
      </Collapse>
    </>
  );
};

export default PdfDownload;
