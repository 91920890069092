import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAddress } from './addressSearchAPI';
import { AddressSearchForm } from '../../entities/Types';
import { AddressSearchRequest } from '../../entities/ApiModel';

export interface AddressSearchResponse {
  match_code: string,
  message: string,
  properties: any[];
  formFields: any;
  total_count: number;
}

export interface AddressState {
  addresses: AddressSearchResponse;
  status: 'idle' | 'loading' | 'failed' | 'reset';
  formFields: AddressSearchForm
}
export const resetAddressState = createAction('REVERT_ADDRESS_STATE');

const initialState: AddressState = {
  addresses: {
    match_code: "",
    message: "",
    properties: [],
    formFields: {},
    total_count: 0
  },
  status: 'idle',
  formFields: {
    propertyStreetAddress: "",
    propertyUnitNumber: "",
    propertyCity: "",
    propertyState: "",
    propertyZip: "",
  }
};

export const searchByAddress = createAsyncThunk(
  'address/search',
  async (addressRequest: AddressSearchRequest) => {
    const response = await fetchAddress(addressRequest);
    return response;
  }
);

export const setAddressToState = createAsyncThunk(
  'address/setAddressToState',
  async (formData: AddressSearchForm) => {
    return formData;
  }
);

export const addressSearchSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchByAddress.pending, (state) => {
        state.status = 'loading';
        state.addresses.properties = [];
        state.addresses.match_code = "";
        state.addresses.message = "";
      })
      .addCase(searchByAddress.fulfilled, (state, action) => {
        state.status = 'idle';
        if (!action.payload)
          return;
        state.addresses.properties = action.payload.properties;
        state.addresses.match_code = action.payload.match_code;
        state.addresses.message = action.payload.message;
        state.addresses.total_count = action.payload.total_count ?? 0;
      })
      .addCase(searchByAddress.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setAddressToState.fulfilled, (state, action) => {
        state.status = 'idle';
        state.formFields = {
          propertyStreetAddress: action.payload.propertyStreetAddress,
          propertyUnitNumber: action.payload.propertyUnitNumber,
          propertyCity: action.payload.propertyCity,
          propertyState: action.payload.propertyState,
          propertyZip: action.payload.propertyZip,
        }
      })
      .addCase(resetAddressState, () => initialState);
  },
});

export const addressList = (state: RootState) => state.rootReducer.address;
export default addressSearchSlice.reducer;
