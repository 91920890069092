import React from 'react'
import Popup from '../popup/Popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'

interface Props {
    isOpen: boolean
    notificationText?: string
    onClose?: React.MouseEventHandler<HTMLElement>;
}

function Exception({ isOpen, notificationText = "SPECTR is experiencing system difficulties. Please resubmit your request.", onClose }: Props) {
    const ExceptionFont = () => {
        return (
            <FontAwesomeIcon fontSize={'40px'} color='white' icon={faWarning} />
        )
    }

    const headerStyle = {
        backgroundImage: 'linear-gradient(180deg, #B3261E 0%, #8C1D18 100%)'
    }

    return (
        <Popup notificationText={notificationText} onClose={onClose} headerGradientStyle={headerStyle} fontComponent={<ExceptionFont />} isOpen={isOpen} ></Popup>
    )
}

export default Exception