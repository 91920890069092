
import { axiosSecuredInstance } from '../../configuration/axiosConfig';
import { ParcelDetailsResponse } from '../../entities/ApiModel';

export const fetchParcelDetails = async (propertyId: string, fips: string): Promise<ParcelDetailsResponse> => {
    let parcelDetailsResponse: ParcelDetailsResponse = {
        propertyDetails: {},
        overviewDetails: {},
        salesDetails: {},
        physicalDetails: {},
        assessmentDetails: {},
        directStarter: {},
        referenceStarter: {},
        mapProperties: {}
    }
    if (!propertyId) {
        return parcelDetailsResponse;
    }
    try {
        const res = await Promise.all([
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/parcels", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId, { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/sales", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/physical-details", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/assessments", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/direct-starters", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/reference-starters", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/latitude-longitude", { params: { fips: fips } })
        ]);
        parcelDetailsResponse.propertyDetails = res[0].data;
        parcelDetailsResponse.overviewDetails = res[1].data;
        parcelDetailsResponse.salesDetails = res[2].data;
        parcelDetailsResponse.physicalDetails = res[3].data;
        parcelDetailsResponse.assessmentDetails = res[4].data;
        parcelDetailsResponse.directStarter = res[5].data;
        parcelDetailsResponse.referenceStarter = res[6].data; //removeDuplicates(res[5].data, res[6].data);
        parcelDetailsResponse.mapProperties = res[7].data;
        return parcelDetailsResponse;
    } catch (e) {
        return parcelDetailsResponse;
    }
};

// function removeDuplicates(directStarterObj: any, referenceStarterObj: any): any {

//     if (directStarterObj == null || !Array.isArray(directStarterObj.direct_starter_details) || directStarterObj.direct_starter_details.length === 0) {
//         return referenceStarterObj;
//     }

//     const directStarterDetails = Array.isArray(directStarterObj?.direct_starter_details)
//         ? directStarterObj.direct_starter_details
//         : [];
//     const referenceStarterDetails = Array.isArray(referenceStarterObj?.reference_starter_details)
//         ? referenceStarterObj.reference_starter_details
//         : [];

//     const directPolicyNumbers = new Set(
//         directStarterDetails.map((detail: any) => detail?.policy_info?.policy_no ?? "")
//     );

//     const filteredReferenceStarter = referenceStarterDetails.filter(
//         (detail: any) => !directPolicyNumbers.has(detail?.policy_info?.policy_no ?? "")
//     );

//     return {
//         ...referenceStarterObj,
//         reference_starter_details: filteredReferenceStarter
//     };
// }
