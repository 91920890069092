import { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetAddressState, addressList, searchByAddress, setAddressToState } from './addressSearchSlice';
import MatchCodes from '../../utils/enums/MatchCodes';
import {
    SINGLE_MATCH_TEXT,
    MULTIPLE_MATCH_TEXT,
    MULTIPLE_MATCH_RESULT_TEXT,
    NO_MATCH_FOUND_TEXT,
    NO_MATCH__FOUND_RESULT_TEXT,
    NO_COVERAGE_TEXT, NO_COVERAGE_RESULT_TEXT, STREET_VALIDATION_ERROR_TEXT, keyPageNo, AddressSearchErrorMessages
} from '../../utils/constants/constants';
import { AddressSearchRequest } from '../../entities/ApiModel';
import SearchForm from './searchForm/SearchForm';
import { AddressSearchForm } from '../../entities/Types';
import PropertyTable from '../../components/ui/propertyTable/PropertyTable';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import styles from './addressSearch.module.css';
import { ADDRESS_TAB, ADDRESS_TAB_HEADER } from '../../utils/constants/tabConstants';
import { clearSessionStorageByKeyStartingWith } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import TabHeader from '../../components/ui/tabHeader/TabHeader';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import { ValidationText } from '../../components/StyledComponents/CommonControls';

export const errorType: string = "Required"
export const errorMessages = {
    streetAddressRequiredError: "StreetRequired",
    InvalidstreetAddressError: "InvalidStreetAddress",
    cityRequiredError: "CityRequired",
    InvalidcityError: "InvalidCity",
    stateRequiredError: "StateRequired",
    InvalidstateError: "InvalidState",
    zipRequiredError: "ZipRequired",
    InvalidZipError: "InvalidZip",
    MinimumZipError: "MinimumZip",
    MaximumZipError: "MaximumZip",
    InvalidUnitError: "InvalidUnitNumber",
    NumericValueInCityError: "NumericValueInCityError",
}

function AddressSearch() {
    const addressListState = useAppSelector(addressList);
    const dispatch = useAppDispatch();
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    async function ValidateAddress(formData: AddressSearchForm) {
        dispatch(resetAddressState())
        clearSessionStorageByKeyStartingWith(keyPageNo + ADDRESS_TAB);
        setMatchCode("");
        setErrorMessage("");
        setLoading(true);
        let addressRequest: AddressSearchRequest = {
            address: formData.propertyStreetAddress.trim(),
            city: formData.propertyCity.trim(),
            state: formData.propertyState.trim(),
            zip: formData.propertyZip.trim(),
            unit_number: formData.propertyUnitNumber.trim(),
        };
        dispatch(setAddressToState(formData))
        dispatch(searchByAddress(addressRequest))
    }

    useEffect(() => {
        if (addressListState
            && addressListState.addresses
            && addressListState.addresses.match_code !== "") {
            setMatchCode(addressListState.addresses.match_code);
            setLoading(false);
        } else {
            setMatchCode("");
        }

        if (addressListState.status === "failed") {
            setLoading(false);
        }
    }, [addressListState]);

    useEffect(() => {
        if (matchCode) {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND_TEXT);
                    setResultText(NO_MATCH__FOUND_RESULT_TEXT);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    setResultText(NO_COVERAGE_RESULT_TEXT);
                    break;
                }
                case MatchCodes.INVALID_ADDRESS.toString(): {
                    setMatchText(STREET_VALIDATION_ERROR_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    const resetError = () => {
        setErrorMessage("");
    }

    const raiseError = (errors: any) => {
        if (addressListState.addresses.match_code && Object.keys(errors).length === 0)
            setMatchCode(addressListState.addresses.match_code);

        if (Object.keys(errors).length === 0)
            setErrorMessage("");

        if (matchCode === MatchCodes.INVALID_ADDRESS.toString() && Object.keys(errors).length !== 0)
            setMatchCode("");

        if (errors.propertyStreetAddress?.type === "Required" && errors.propertyCity?.type === "Required" &&
            errors.propertyState?.type === "Required" && errors.propertyZip?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required" &&
            (errors.propertyState?.type === "Required" || errors.propertyCity?.type === "Required")) {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.Street_Address_Required);
        }

        if (errors.propertyCity?.type === "Required" ||
            errors.propertyState?.type === "Required" || errors.propertyZip?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.City_State_Zip_Required);
        }

        if (errors.propertyStreetAddress?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Street_Address);
        }

        if (errors.propertyCity?.message === "NumericValueInCityError") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Numeric_Error);
        }

        if (errors.propertyCity?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Field);
        }

        if (errors.propertyState?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_State_Field);
        }

        if (errors.propertyZip?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Zip_Field);
        }

        if (errors.propertyUnitNumber?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Unit_Field);
        }

        if (errors.propertyZip?.type === "minLength") {
            setErrorMessage(AddressSearchErrorMessages.Minimum_Zip_Error);
        }

        if (errors.propertyZip?.type === "maxLength") {
            setErrorMessage(AddressSearchErrorMessages.Maximum_Zip_Error);
        }
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box className={styles.boxLayout}>
                <TabPanel value="addressTab">
                    <div className="">
                        <TabHeader headerText={"Address Search"} />
                        <Box sx={{ marginTop: '6px', marginBottom: '15px' }}>
                            <ValidationText>*City & State or ZIP Required</ValidationText>
                        </Box>
                        <SearchForm validateAddress={ValidateAddress} raiseError={raiseError} resetErrorMessage={resetError} formFields={addressListState?.formFields} />
                        {
                            errorMessage
                            &&
                            <ErrorMessage errorMessageText={errorMessage} />
                        }
                        {
                            matchCode
                            &&
                            <MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                        }
                        {
                            addressListState
                            && addressListState.addresses
                            && addressListState.addresses.properties.length > 0
                            &&
                            <div>
                                <Box sx={{ mt: '15px', width: '100%', overflow: 'hidden' }}>
                                    <PropertyTable tabName={ADDRESS_TAB}
                                        tabHeader={ADDRESS_TAB_HEADER}
                                        totalRowCount={addressListState.addresses.total_count}
                                        propertyData={addressListState.addresses.properties}
                                    />
                                </Box>
                            </div>
                        }
                    </div>
                </TabPanel>
            </Box>
        </>
    )
}

export default AddressSearch;