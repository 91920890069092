import React from 'react'
import { PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Grid } from '@mui/material'
import styles from './policySearchResult.module.css';
import styled from '@emotion/styled';

const SectionDataBox = styled(Box)`
        padding: 15px;
        box-shadow: none;
`;

function APNListLastLine() {
    return (
        <SectionDataBox className={styles.topPadding}>
            <Grid container>
                <Grid item xs={0.3}>
                </Grid>
                <Grid item xs={11.7} className={styles.lastLineBorder}><PropertyHeader></PropertyHeader></Grid>
            </Grid>
        </SectionDataBox>
    )
}

export default APNListLastLine