import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

function Footer() {
  const Div = styled('div')(({ theme }) => ({
    fontSize: 14,
    marginTop: 6
  }));

  const FooterLink = styled(Link)(({ theme }) => ({
    paddingRight: '25px',
    color: '#0075aa'
  }));

  return (
    <Box>
      <FooterLink href="https://www.stewart.com/en/privacy.html" target="_blank">Privacy Policy</FooterLink>
      <FooterLink href="https://www.stewart.com/en/terms-of-use.html" target="_blank">Terms of Use</FooterLink>
      <FooterLink href="https://www.stewart.com/en/protecting-customer-information.html" target="_blank">Protecting Customer Information</FooterLink>
      <Div>&#169; 2024 Stewart Title Guaranty Company. All Rights Reserved. Trademarks are the property of their respective owners.</Div>
    </Box>
  );
}
export default Footer;