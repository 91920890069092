import MatchCodes from "../enums/MatchCodes";
import { v4 as uuidv4 } from 'uuid';
import config from "../../appsettings.json";
import { LEGAL_SEARCH_FORM, LEGAL_SEARCH_TAB, POLICY_SEARCH_TAB } from "../constants/tabConstants";
import { PolicyLegalSearch_Logging_Event, PolicySearch_Loggin_Event } from "../constants/constants";

export const getLengthOfAstrik = (value: string) => {
    return value?.replace(/[^*]/g, "").length;
}

export const getLength_of_Base64String = (base64String: string) => {
    try {
        const byteCharacters = atob(base64String);
        return byteCharacters.length;
    }
    catch {
        return 0
    }
}

export const SearchLogResult = (code: any) => {
    switch (code) {
        case MatchCodes.UNIQUE_MATCH.toString(): {
            return "Single Match";
        }
        case MatchCodes.MULTIPLE_MATCH.toString(): {
            return "Multiple Match";
        }
        case MatchCodes.NO_COVERAGE.toString(): {
            return "ZIP4 Look-up";
        }
        case MatchCodes.INVALID_ADDRESS.toString(): {
            return "Invalid Address - No Match Found";
        }
        case MatchCodes.NO_MATCH.toString(): {
            return "Valid Address - No Match Found";
        }
    }
}

export const parseJwt = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
}

export const formatUnitNumberField = (unitType: string, unitNumber: string) => {
    let formattedUnitNumber = "";
    if (unitType && unitNumber) {
        formattedUnitNumber = unitType + " " + unitNumber;
    }
    else if (!unitType && unitNumber) {
        formattedUnitNumber = "# " + unitNumber;
    }
    else if (unitType && !unitNumber) {
        formattedUnitNumber = unitType;
    }

    return formattedUnitNumber
}

export const formatZip4Field = (zip: string, zip4: string) => {
    let formattedZip = "";
    if (zip && zip4) {
        formattedZip = zip + "-" + zip4;
    }
    else if (!zip && zip4) {
        formattedZip = zip4;
    }
    else if (zip && !zip4) {
        formattedZip = zip;
    }
    return formattedZip
}

export const formatMailingAddress = (address: any, city: any, state: any, zip: any, unitDetails: string) => {
    let mailingAddress = ""
    if (address)
        mailingAddress += address;
    if (unitDetails)
        mailingAddress += " " + unitDetails + ","
    if (city)
        mailingAddress += (mailingAddress && !unitDetails) ? ", " + city : " " + city;
    if (state)
        mailingAddress += (mailingAddress && city) ? ", " + state : " " + state;
    if (zip)
        mailingAddress += " " + zip;
    return mailingAddress;
}

export const convertToPercent = (value: string) => {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
        return "";
    } else {
        return ((parsedValue * 100).toFixed(0));
    }
}

export const addThousandsSeparator = (value: any) => {
    let parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
        return "";
    } else {
        return parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const FormatDateField = (date: string) => {
    var formattedDate = "";
    if (date) {
        formattedDate = date.replace(/-/g, '/')
    }
    return formattedDate;
}

export const GetUUId = () => {
    const guid: string = uuidv4();
    return guid;
}

export const getSessionStorage = (keyName: any) => {
    const storedData = sessionStorage.getItem(keyName);
    return storedData;
};

export const setSessionStorage = (keyName: any, value: number) => {
    sessionStorage.setItem(keyName, value?.toString());
};

export const clearSessionStorageByKeyStartingWith = (startingString: string) => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key: string | null = sessionStorage.key(i);
        if (key && key.startsWith(startingString)) {
            sessionStorage.removeItem(key);
        }
    }
}

export const removeKeySessionStorageItemsStartingWith = (prefix: string) => {
    for (let key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key) && key.startsWith(prefix)) {
            sessionStorage.removeItem(key);
        }
    }
}

export const getEnvironmentTitle = () => {
    const { env } = config.AzureSettings ?? "";
    switch (env) {
        case "dev":
            return "SPECTR DEV";
        case "qa":
            return "SPECTR QA";
        case "uat":
            return "SPECTR UAT";
        default:
            return "SPECTR";
    }
};

export const getLoggingEventName = (tabName: string) => {
    switch (tabName) {
        case LEGAL_SEARCH_TAB:
            return PolicyLegalSearch_Logging_Event.Legal_Search_Event
        case POLICY_SEARCH_TAB:
            return PolicySearch_Loggin_Event.Policy_Search_Event
        default:
            return "";
    }
}

export const validateLotFromAndTo = (fromValue: string | undefined, toValue: string | undefined) => {
    fromValue = (fromValue ?? "").trim();
    toValue = (toValue ?? "").trim();

    if (!fromValue || !toValue || (fromValue === toValue)) {
        return true;
    }

    if (!isNumber(fromValue) || !isNumber(toValue)) {
        return true;
    }

    if (parseInt(fromValue) < parseInt(toValue)) {
        return true;
    }
    return false;
}

export const isNumber = (value: string) => {
    return /^-?\d+$/.test(value);

}
