import React from "react";
import { PropertyHeader, SectionCard, SectionDataBox, SectionHeader } from "../../../components/StyledComponents/ParcelDetailStyles"
import { Collapse, Grid, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { addThousandsSeparator } from "../../../utils/common/commonUtils";

function PhysicalCharacteristic(props: any) {
    const [physicalCharacteristicOpen, setPhysicalCharacteristic] = React.useState(false);
    let physicalDetails: any;
    if (props && props.physicalDetails) {
        physicalDetails = props.physicalDetails;
    }
    return (
        <div>
            {
                props &&
                props.physicalDetails &&
                physicalDetails &&
                <SectionCard>
                    <SectionHeader onClick={() => setPhysicalCharacteristic(!physicalCharacteristicOpen)} variant="h6">
                        Physical Characteristics &nbsp; {physicalCharacteristicOpen ? <KeyboardArrowUpIcon data-testid="PhysicalCharacteristicClose" />
                            : <KeyboardArrowDownIcon data-testid="PhysicalCharacteristicOpen" />}
                    </SectionHeader>
                    <SectionDataBox>
                        <Grid container>
                            <Grid item xs={2}><PropertyHeader>Bedrooms</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="Bedrooms"><Typography>{physicalDetails.no_of_bedroom}</Typography> </Grid>
                            <Grid item xs={1}><PropertyHeader>Year Built</PropertyHeader></Grid>
                            <Grid item xs={2} data-testid="YearBuilt"><Typography>{physicalDetails.year_built}</Typography></Grid>
                            <Grid item xs={1}><PropertyHeader>Living Area (SF)</PropertyHeader></Grid>
                            <Grid item xs={3} data-testid="LivingArea"><Typography>{addThousandsSeparator(physicalDetails.living_area)}</Typography></Grid>
                        </Grid>
                    </SectionDataBox>
                    <Collapse in={physicalCharacteristicOpen} timeout="auto"
                        unmountOnExit>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Bathrooms Full/Partial</PropertyHeader></Grid>
                                {/* <Grid item xs={3}><Typography>{physicalDetails.no_of_bath}{physicalDetails.no_of_bath && physicalDetails.no_of_partial_bath ? "/" : ""}{physicalDetails.no_of_partial_bath}</Typography> </Grid> */}
                                <Grid item xs={3} data-testid="Bathrooms"><Typography>{physicalDetails.no_of_bath + "/" + physicalDetails.no_of_partial_bath}</Typography> </Grid>
                                <Grid item xs={1}><PropertyHeader>Garage/No. of Cars</PropertyHeader></Grid>
                                <Grid item xs={2} data-testid="Garage-NoOfCars"><Typography>{physicalDetails.garage_no_of_cars}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Price ($/SF)</PropertyHeader></Grid>
                                <Grid item xs={2} data-testid="Price"><Typography>{physicalDetails.prices ? "$" : ""}{physicalDetails.prices ? Number(physicalDetails.prices).toLocaleString() : ""}{physicalDetails.prices ? "/SF" : ""}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Total Rooms</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="TotalRooms">{physicalDetails.total_no_rooms}</Typography> </Grid>
                                <Grid item xs={1}><PropertyHeader>Stories/Floors</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="Stories-Floors">{physicalDetails.no_of_stories}{physicalDetails.no_of_stories && physicalDetails.floor ? "/" : ""}{physicalDetails.floor}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Lot Size (SF/AC)</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="LotSize">{physicalDetails.lot_size_sf_ac}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Construction Type</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="ConstructionType">{physicalDetails.type_construction}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>No. of Units</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="NoOfUnits">{physicalDetails.no_of_units}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Fireplace</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="FirePlace">{physicalDetails.no_of_fireplace}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Exterior Walls</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="ExteriorWalls">{physicalDetails.exterior_wall_code}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>No. of Buildings</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="NoOfBuildings">{physicalDetails.no_of_buildings}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Pool</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="Pool">{physicalDetails.pool_code}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Roof Material/Type</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="RoofMaterial">{physicalDetails.roof_cover_code}{physicalDetails.roof_cover_code && physicalDetails.roof_type_code ? "/" : ""}{physicalDetails.roof_type_code}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Style</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="Style">{physicalDetails.building_style_code}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Heat Type</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="HeatType">{physicalDetails.heating_code}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Foundation Type</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="FoundationType">{physicalDetails.foundation_code}</Typography> </Grid>
                                <Grid item xs={1}><PropertyHeader>View</PropertyHeader></Grid>
                                <Grid item xs={2}><Typography data-testid="View">{physicalDetails.view}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>A/C</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="A/C">{physicalDetails.ac}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Property Type</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="PropertyType">{physicalDetails.property_type}</Typography></Grid>
                                {/* <Grid item xs={1}><PropertyHeader>Elevator</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography>{physicalDetails.elevator_code}</Typography></Grid> */}
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Land use</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="LandUse">{physicalDetails.land_use}</Typography></Grid>
                                <Grid item xs={1}><PropertyHeader>Zoning</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="Zoning">{physicalDetails.zoining}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                        <SectionDataBox>
                            <Grid container>
                                <Grid item xs={2}><PropertyHeader>Basement Type/Area</PropertyHeader></Grid>
                                <Grid item xs={3}><Typography data-testid="BasementType">{physicalDetails.basement_type_area}</Typography></Grid>
                            </Grid>
                        </SectionDataBox>
                    </Collapse>
                </SectionCard>
            }
        </div>
    )
}

export default PhysicalCharacteristic;