import { Box, Typography } from '@mui/material'
import React from 'react'
import { SectionHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
    claimNo: string,
    subDivisionName: string,
    searchProximity: string,
    isSubDivision: Boolean
    handleDirectStarterSectionClick: Function;
    rowNumber: string
    policyNumber: string
}

function ReferenceStarterHeader({ claimNo, subDivisionName, searchProximity, isSubDivision, rowNumber, handleDirectStarterSectionClick, policyNumber }: Props) {
    const [referenceStarterOpen, setReferenceStarter] = React.useState(false);
    let sectionHeaderText = "";
    if (searchProximity && !isNaN(parseFloat(searchProximity))) {
        searchProximity = parseFloat(searchProximity).toFixed(2);
    }
    // if (referenceStarter && objectLength > 0) {
    const searchText: string = isSubDivision ? "Subdivision" : "Proximity";
    const divisionText: string = isSubDivision ? subDivisionName : searchProximity + " Miles";
    sectionHeaderText = ' - ' + searchText + ': ' + divisionText
    // }
    const handleClick = () => {
        setReferenceStarter(!referenceStarterOpen);
        handleDirectStarterSectionClick(rowNumber)
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <SectionHeader sx={{ paddingBottom: "0px" }} onClick={handleClick} variant="h6">
                Reference Starter  &nbsp;
                {sectionHeaderText}
                &nbsp; {referenceStarterOpen ? <KeyboardArrowUpIcon data-testid={policyNumber + "_ReferenceStarterClose"} />
                    : <KeyboardArrowDownIcon data-testid={policyNumber + "_ReferenceStarterOpen"} />}
            </SectionHeader>
            {
                claimNo &&
                <Typography data-testid={policyNumber + "ClaimNo"} sx={{ color: '#E00000', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {claimNo}</Typography>
            }
        </Box>
        // <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "10px" }}>
        //     <SectionHeader sx={{ paddingBottom: "0px" }} onClick={() => setReferenceStarter(!referenceStarterOpen)} variant="h6">
        //         Reference Starter &nbsp; {referenceStarterOpen ? <KeyboardArrowUpIcon />
        //             : <KeyboardArrowDownIcon />}
        //     </SectionHeader>
        //     {
        //         claimNo &&
        //         <Typography sx={{ color: '#E00000', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {claimNo}</Typography>
        //     }
        // </Box>
    )
}

export default ReferenceStarterHeader