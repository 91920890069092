import React, { useEffect, useState } from 'react';
import PdfDownload from '../pdfViewer/PdfDownload';
import PdfViewer from '../pdfViewer/PdfViewer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { policyDoc, resetDocumentState } from '../../../common/Slice/policyDocumentSlice';
import { DocumentSizeLimit } from '../../../utils/constants/constants';
import { GetUUId, getLength_of_Base64String } from '../../../utils/common/commonUtils';

interface PdfDocument {
    dDocName: string;
    fileName: string;
    base64String: string;
    pdfUrl?: string; // azure Pdf URL
}

function DocumentImage() {
    const policyDocumentList = useAppSelector((state: any) => state.rootReducer.policyDocument);
    // const policyDocState = useAppSelector(policyDoc);
    const dispatch = useAppDispatch();
    const [pdfDocument, setPdfDocument] = useState<PdfDocument | null>(null);
    const [openPdfViewer, setOpenPdfViewer] = useState(false);
    const [isDocumentLoaded, setDocumentLoaded] = useState(false);
    const [randomUUId, setRandomUUId] = useState("");

    useEffect(() => {
        if (policyDocumentList && policyDocumentList.status === 'idle') {
            const doc: any = policyDocumentList;
            if (doc?.documentList?.documentData) {
                setPdfDocument({
                    base64String: doc.documentList?.documentData,
                    fileName: doc.documentList?.policyNumber ? doc.documentList.policyNumber : doc.documentList?.documentId,
                    dDocName: ""
                });
                setRandomUUId(GetUUId());
                setOpenPdfViewer(true);
                setDocumentLoaded(true);
                console.log(randomUUId)
            }
        }
    }, [policyDocumentList]);

    const onClose = () => {
        dispatch(resetDocumentState());
        setOpenPdfViewer(false);
        setDocumentLoaded(false);
    }

    return (
        <>
            {pdfDocument && (
                getLength_of_Base64String(pdfDocument.base64String) > DocumentSizeLimit ?
                    <PdfDownload key={randomUUId} base64String={pdfDocument.base64String}
                        fileName={pdfDocument.fileName + ".pdf"}
                        policyNumber={pdfDocument.fileName}
                        onClose={onClose} />

                    :
                    <PdfViewer key={randomUUId} randomId={randomUUId} isOpen={openPdfViewer} pdf={pdfDocument} onClose={onClose} />
            )}
        </>
    )
}

export default DocumentImage;
