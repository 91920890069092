import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { setTabToState } from './tabIdentifierSlice';
import { ParcelDetail_Log_Event } from '../../../utils/constants/constants';
import logAPI from '../../../common/Api/logAPI';
import { formatZip4Field } from '../../../utils/common/commonUtils';
import SpectrTable from '../spectr-table/SpectrTable';

type Props = {
    propertyData: any;
    tabName: any;
    tabHeader: any;
    totalRowCount: number;
};

interface Column {
    id: 'apn' | 'ownerName' | 'address' | 'city' | 'state' | 'zip' | 'landUse' | 'briefLegal';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

interface Data {
    apn: string,
    ownerName: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    landUse: string,
    briefLegal: string,
    propertyId: string,
    fips: string,
    unit: string,
    unitType: string
}

const PropertyTable = ({ propertyData, tabName, tabHeader, totalRowCount }: Props) => {
    // const tabState = useAppSelector(tabIdentifier);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const columns: readonly Column[] = [
        { id: 'apn', label: 'APN', minWidth: 130 },
        { id: 'ownerName', label: 'OWNER NAME', minWidth: 250 },
        {
            id: 'address',
            label: 'ADDRESS',
            minWidth: 170,
        },
        {
            id: 'city',
            label: 'CITY',
            minWidth: 120,
        },
        {
            id: 'state',
            label: 'STATE',
            minWidth: 50,
        },
        {
            id: 'zip',
            label: 'ZIP',
            minWidth: 85,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: 'landUse',
            label: 'LAND USE',
            minWidth: 170,
        },
        {
            id: 'briefLegal',
            label: 'BRIEF LEGAL',
            minWidth: 170,
        }
    ];

    function createData(
        apn: string,
        ownerName: string,
        address: string,
        city: string,
        state: string,
        zip: string,
        landUse: string,
        briefLegal: string,
        propertyId: string,
        fips: string,
        unit: string,
        unitType: string
    )
        : Data {
        return { apn, ownerName, address, city, state, zip, landUse, briefLegal, propertyId, fips, unit, unitType };
    }

    const rows: any[] = [];
    propertyData?.forEach((element: any) => {
        var data = createData(element.apn, element.ownerName, element.address, element.city,
            element.state, formatZip4Field(element.zip, element.zip4), element.landUse, element.breifLegal,
            element.propertyId, element.fips, element.unitNumber, element.unit_type);
        rows.push(data);
    });

    function onRowClicked(row: any) {
        if (!row)
            return;
        let query = "/parcel-detail/" + row.propertyId + "?fips=" + row.fips;
        navigate(query);
        dispatch(setTabToState(tabName));
        logAPI({
            Event: ParcelDetail_Log_Event,
            FullStreetAddress: row.address,
            city: row.city,
            state: row.state,
            zip: row.zip,
            UnitNumber: row.unit,
            APN: row.apn,
            Fips: row.fips,
            SearchOptionUsed: tabHeader
        });
    }

    return (
        <SpectrTable tabName={tabName} onRowClicked={onRowClicked} columns={columns} rows={rows} totalRowCount={totalRowCount} />
    )
}

export default PropertyTable