import { Box, Button, Typography } from "@mui/material"
import AssessmentSearchForm from "./assessmentSearchForm/AssessmentSearchForm"
import { TabPanel } from "@mui/lab"
import styles from './legalSearch.module.css';
import {  ValidationText } from "../../components/StyledComponents/CommonControls";
import LegalSearchForm from "./legalSearchForm/LegalSearchForm";
import React, { useEffect, useState } from "react";
import { LegalSearchFormType } from "../../entities/Types/LegalSearchForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LegalSearchData, resetLegalSearchState, searchByLegalSearch, setLegalSearchToState } from "./legalSearchForm/LegalSearchSlice";
import { LegalSearchRequest } from "../../entities/ApiModel/LegalSearchRequest";
import MatchCodes from "../../utils/enums/MatchCodes";
import {
    EXCEPTION_MATCH_TEXT, LegalSearchFormErrorMessages, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND,
    SINGLE_MATCH_TEXT,
    keyEntriesPerPage,
    keyPageNo
} from "../../utils/constants/constants";
import LoadingProgressModel from "../../components/ui/circularProgress/LoadingProgress";
import MatchStatus from "../../components/ui/matchStatus/MatchStatus";
import PolicySearchResult from "../policySearch/searchResult/PolicySearchResult";
import { ASSESSMENT_SEARCH_FORM, LEGAL_SEARCH_FORM, LEGAL_SEARCH_TAB } from "../../utils/constants/tabConstants";
import ErrorMessage from "../../components/ui/errorMessage/ErrorMessage";
import { getSessionStorage, setSessionStorage } from '../../utils/common/commonUtils';

// const PolicySearchButton = styled(Button)`
//     color:#015E88;
//     border-color:#015E88;
//     text-transform: none;
//     box-shadow: 0px 2px 3px 0px #00000040;
//     padding: 8px 22px 8px 22px;
//     border-top-left-radius: 0px;
//     border-bottom-left-radius: 0px;
//     `;

// background-image: linear-gradient(#0075aa, #03567b);
// text-transform: none;
// box-shadow: 0px 2px 3px 0px #00000040;

const LegalSearch = () => {

    const [isAssesementSearch, setAssesementSearch] = React.useState(true);
    const [isPolicyLegalSearch, setPolicyLegalSearch] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const dispatch = useAppDispatch();
    const legalSearchState = useAppSelector(LegalSearchData);

    const handleAssesementSearchClick = () => {
        setPolicyLegalSearch(false)
        setAssesementSearch(true)
    }

    const handlePolicySearchClick = () => {
        setAssesementSearch(false)
        setPolicyLegalSearch(true)
    }

    async function handleSubmit(formData: LegalSearchFormType) {
        try {
            if (!formData)
                return;
            dispatch(resetLegalSearchState());

            const defaultPageNo = 1;
            const defaultRowsPerPage = 10;
            setSessionStorage(keyPageNo + LEGAL_SEARCH_TAB, defaultPageNo);
            let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + LEGAL_SEARCH_TAB) ?? defaultPageNo.toString());
            let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + LEGAL_SEARCH_TAB) ?? defaultRowsPerPage.toString())
            let request: LegalSearchRequest = {
                state: formData.state.id ?? "",
                fips: formData.fips.fips ?? "",
                subdivision_lot_from: formData.subdivision_lot_from ?? "",
                subdivision_lot_to: formData.subdivision_lot_to ?? "",
                block: formData.block ?? "",
                subdivision: formData.subdivision ?? "",
                phase: formData.phase ?? "",
                condominium_building: formData.condominium_building ?? "",
                condominium_unit_from: formData.condominium_unit_from ?? "",
                condominium_unit_to: formData.condominium_unit_to ?? "",
                condominium: formData.condominium ?? "",
                volume: formData.volume ?? "",
                book: formData.book ?? "",
                page: formData.page ?? "",
                section: formData.section ?? "",
                township: formData.township ?? "",
                range: formData.range ?? "",
                abstract_name: formData.abstract_name ?? "",
                abstract_number: formData.abstract_number ?? "",
                quarter1: formData.quarter1 ?? "",
                quarter2: formData.quarter2 ?? "",
                quarter3: formData.quarter3 ?? "",
                offset_value: ((page - 1) * rowsPerPage).toString(),
                fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
            }
            formData.pageNo = page;
            setMatchCode("");
            setLoading(true);

            dispatch(searchByLegalSearch(request));
            dispatch(setLegalSearchToState(JSON.parse(JSON.stringify(formData))));
        }
        catch (e) {

        }
    }


    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                // case MatchCodes.INVALID_APN.toString(): {
                //     setMatchText(INVALID_APN_TEXT);
                //     break;
                // }
                default: {
                    setMatchText("");
                }
            }
        } else {
            setMatchText("");
        }
    }, [matchCode]);

    useEffect(() => {
        if (legalSearchState
            && legalSearchState.property
            && legalSearchState.property.match_code !== "" && !legalSearchState.property.isExecuting) {
            setLoading(false)
            setMatchCode(legalSearchState.property.match_code);
            // console.log(legalSearchState)
        }

        if (legalSearchState.status === "failed") {
            setLoading(false);
        }
    }, [legalSearchState]);

    async function handleClearSearch() {
        // if (matchCode === MatchCodes.INVALID_APN.toString())
        setMatchCode("");
    }

    useEffect(() => {
        if (legalSearchState?.formFields?.tabName === LEGAL_SEARCH_FORM) {
            setPolicyLegalSearch(true);
            setAssesementSearch(false);
        }
        else if (legalSearchState?.formFields?.tabName === ASSESSMENT_SEARCH_FORM) {
            setAssesementSearch(true);
            setPolicyLegalSearch(false);
        }
    }, [legalSearchState?.formFields]);


    const raiseError = (errors: any, formValues: any) => {
        if (errors.state?.id?.message === "Required" &&
            errors.fips?.fips?.message === "Required") { //&& areAllPropertiesEmpty(errors)) {
            setErrorMessage(LegalSearchFormErrorMessages.NoFieldError);
        }
        else if (errors.fips?.fips?.message === "Required") {
            setErrorMessage(LegalSearchFormErrorMessages.CountyRequiredError);
        }
        else if (errors.fieldValidationError?.type === "Empty_Form_Value") {
            setErrorMessage(LegalSearchFormErrorMessages.NoLegalFields);
        }
        else if (errors.subdivision_lot_from?.type === "Greater_Value_Error" || errors.subdivision_lot_to?.type === "Greater_Value_Error") {
            setErrorMessage(LegalSearchFormErrorMessages.LotFromGreaterValue);
        }
        else if (Object.keys(errors).length > 0) {
            const keys = Object.keys(errors);
            let message = keys?.length > 0 ? errors[keys[0]]?.message : "";
            setErrorMessage(message);
        }
        else {
            setErrorMessage("")
        }
    }

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: LegalSearchRequest = {
            state: legalSearchState?.formFields?.state.id ?? "",
            fips: legalSearchState?.formFields?.fips.fips ?? "",
            subdivision_lot_from: legalSearchState?.formFields?.subdivision_lot_from ?? "",
            subdivision_lot_to: legalSearchState?.formFields?.subdivision_lot_to ?? "",
            block: legalSearchState?.formFields?.block ?? "",
            subdivision: legalSearchState?.formFields?.subdivision ?? "",
            phase: legalSearchState?.formFields?.phase ?? "",
            condominium_building: legalSearchState?.formFields?.condominium_building ?? "",
            condominium_unit_from: legalSearchState?.formFields?.condominium_unit_from ?? "",
            condominium_unit_to: legalSearchState?.formFields?.condominium_unit_to ?? "",
            condominium: legalSearchState?.formFields?.condominium ?? "",
            volume: legalSearchState?.formFields?.volume ?? "",
            book: legalSearchState?.formFields?.book ?? "",
            page: legalSearchState?.formFields?.page ?? "",
            section: legalSearchState?.formFields?.section ?? "",
            township: legalSearchState?.formFields?.township ?? "",
            range: legalSearchState?.formFields?.range ?? "",
            abstract_name: legalSearchState?.formFields?.abstract_name ?? "",
            abstract_number: legalSearchState?.formFields?.abstract_number ?? "",
            quarter1: legalSearchState?.formFields?.quarter1 ?? "",
            quarter2: legalSearchState?.formFields?.quarter2 ?? "",
            quarter3: legalSearchState?.formFields?.quarter3 ?? "",
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }
        setMatchCode("");
        setMatchText("");
        let formData = { ...legalSearchState?.formFields, "pageNo": page }
        dispatch(searchByLegalSearch(request));
        dispatch(setLegalSearchToState(formData));
    }

    const handleChangeRowsPerPage = () => {
        dispatch(resetLegalSearchState());
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box className={styles.boxLayout}>
                <TabPanel value="legalSearchTab">
                    <Button sx={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "white" }}
                        className={isAssesementSearch ? styles.activeButton : styles.inactiveButton}
                        onClick={handleAssesementSearchClick}
                        type="button" size="large" variant={isAssesementSearch ? "contained" : "outlined"}>
                        <Typography textTransform={'uppercase'}
                            className={isAssesementSearch ? styles.activeFontColor : styles.inactiveFontcolor}>
                            Assessment Legal Search</Typography>
                    </Button>
                    <Button sx={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", backgroundColor: "white" }}
                        className={isPolicyLegalSearch ? styles.activeButton : styles.inactiveButton} onClick={handlePolicySearchClick}
                        type="button" size="large" variant={isPolicyLegalSearch ? "contained" : "outlined"}>
                        <Typography textTransform={'uppercase'}
                            className={isPolicyLegalSearch ? styles.activeFontColor : styles.inactiveFontcolor}>
                            Policy Legal Search</Typography>
                    </Button>
                    <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                        <ValidationText>*State, County and one additional field required</ValidationText>
                    </Box>
                    {
                        isAssesementSearch && <AssessmentSearchForm />
                    }
                    {
                        isPolicyLegalSearch && <LegalSearchForm raiseError={raiseError} onSubmit={handleSubmit} onClear={handleClearSearch} formFields={legalSearchState?.formFields} />
                    }
                    {
                        isPolicyLegalSearch && errorMessage
                        &&
                        <ErrorMessage errorMessageText={errorMessage} />
                    }
                    {
                        isPolicyLegalSearch &&
                        <MatchStatus matchCode={matchCode} matchText={matchText} />
                    }
                    {
                        isPolicyLegalSearch
                        && legalSearchState
                        && legalSearchState.property
                        && Object.keys(legalSearchState.property.LegalSearch).length > 0
                        &&
                        // <PolicySearchResult tabName={LEGAL_SEARCH_TAB} propertyList={legalSearchState.property.LegalSearch} />
                        <PolicySearchResult totalRowCount={legalSearchState?.property?.total_count ?? 0}
                            tabName={LEGAL_SEARCH_TAB} fetchNextData={handleFetchNext}
                            propertyList={legalSearchState.property.LegalSearch}
                            changeRowsPerPage={handleChangeRowsPerPage} />
                    }

                </TabPanel>
            </Box>
        </>
    )
}

export default LegalSearch