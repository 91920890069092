import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';

export const StyledAutocomplete = styled(Autocomplete)`
  .Mui-disabled {
    background-color: #f5f5f5;
    pointer-events: none;
    color:#BDBDBD;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    color:#558FAA;
  }
`;