import logAPI from "../../common/Api/logAPI";
import { axiosSecuredInstance } from "../../configuration/axiosConfig";
import { PolicySearchRequest } from "../../entities/ApiModel/PolicySearchRequest";
import { PolicySearch_Loggin_Event } from "../../utils/constants/constants";

export const fetchByPolicyNumber = async (PolicySearchRequest: PolicySearchRequest) => //policyNumber: string, offset_value: string, fetchnext_value: string) => {
{
    if (!PolicySearchRequest || !PolicySearchRequest.policy_file_number) {
        return;
    }
    let config = {
        method: 'post',
        url: 'api/v1/policy/search',
        data: JSON.stringify({ "policy_file_number": PolicySearchRequest.policy_file_number, "offset_value": PolicySearchRequest.offset_value, "fetchnext_value": PolicySearchRequest.fetchnext_value })
    };
    const response: any = (await axiosSecuredInstance.request(config)).data;
    logAPI({
        Event: PolicySearch_Loggin_Event.Policy_Search_Event,
        UserInput: PolicySearchRequest.policy_file_number,
        SearchResult: response?.message
    });
    return response;
};