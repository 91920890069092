import React from 'react'
import { SectionDataBox, SectionHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import { Box, Grid, Typography } from '@mui/material'

function RefernceStarterEmptySection() {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <SectionHeader sx={{ paddingBottom: "10px" }} variant="h6">
                    Reference Starter
                    {/* &nbsp; {directStarterOpen ? <KeyboardArrowUpIcon />
                        : <KeyboardArrowDownIcon />} */}
                </SectionHeader>
            </Box>
            <SectionDataBox>
                <Grid container>
                    <Grid item xs={12}><Typography>No Reference Starter(s) Available</Typography> </Grid>
                </Grid>
            </SectionDataBox>
        </>
    )
}

export default RefernceStarterEmptySection