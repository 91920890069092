import styled from '@emotion/styled';
import { Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)`
        .MuiTabs-scroller.MuiTabs-fixed  {
            border-radius: 5px 0px 0px 0px;
        }
        .MuiTabs-indicator {
            background-color: #015e88 !important;
        }
        .MuiTab-root.Mui-selected {
            color: #015e88 !important;
            background-color: white;
        }
`;