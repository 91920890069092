import No_Image_Icon from '../../../assets/images/map/No_Image_Icon.png';
import styles from './NoMap.module.css';

function NoMap() {
    return (
        <div className={styles.No_Map_Align}>
            <img alt="NoMapImage"  src={No_Image_Icon} />
        </div>
    )
}

export default NoMap