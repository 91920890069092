import { Button, styled, Paper, Typography } from "@mui/material";

export const GradientButton = styled(Button)`
    background-image: linear-gradient(#0075aa, #03567b);
    text-transform: none;
    box-shadow: 0px 2px 3px 0px #00000040;
`;

export const ClearButton = styled(Button)`
    color:#0075AA;
    border-color:#0075AA;
    text-transform: none;
    box-shadow: 0px 2px 3px 0px #00000040;
`;

export const ValidationText = styled(Typography)`
    color:#7F7F7F;
    font-size: 14px;
    font-weight: 400;
`;

export const GridItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none"
}));